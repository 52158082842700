<template>
  <div id="login">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { helperToken } from '@helpers'
import { useStoreAreas } from '@stores'
import { useRoute } from 'vue-router'
import Tela from '@components/pages/login/partials/Tela.vue'

const storeAreas = useStoreAreas()
const route = useRoute()

function bloquearRotaSeLogado() {
  if (!helperToken.detectarTokenOk()) return

  if (dominioPersonalizado()) {
    window.location.assign('/loading')
  } else {
    const hotlink = route.params.hotlink
    window.location.assign(`/${hotlink}/loading`)
  }
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

watch(
  () => storeAreas.area,
  (novaArea) => {
    if (novaArea) {
      bloquearRotaSeLogado()
    }
  },
  { immediate: true }
)

onMounted(() => {
  bloquearRotaSeLogado()
})
</script>
