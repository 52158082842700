<template>
  <section v-if="storeAreas.area" class="cursos" :class="tela.layout" :style="conteudoEstilo">
    <div class="apresentacao">
      <div class="sombra" :style="estiloSombra"></div>
      <div class="fundo desktop">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${tela.imagemCapaDesktop || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="fundo mobile">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${tela.imagemCapaMobile || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="texto">
        <h2 :style="capaTextoEstilo">{{ tela.textoCapaTitulo || '' }}</h2>
        <p :style="capaTextoEstilo">{{ tela.textoCapaDescricao || '' }}</p>
        <a :href="tela.linkCapa" v-if="tela.textoCapaBotao" :style="botaoEstilo">{{ tela.textoCapaBotao || '' }}</a>
      </div>
    </div>
    <a class="banner" :href="tela.linkBanner" target="_blank" v-if="tela.linkBanner">
      <img class="desktop" :src="`${tela.imagemBannerDesktop || 'https://arquivos.levelmember.com/imagens/default/banner-promocional.png'}`" />
      <img class="mobile" :src="`${tela.imagemBannerMobile || 'https://arquivos.levelmember.com/imagens/default/banner-promocional-mobile.png'}`" />
    </a>
    <div class="lista">
      <div v-for="curso in storeCursos.cursos" :key="curso._id" class="curso" :style="quadroEstilo">
        <div class="capa" :class="{ bloqueado: curso.bloqueado }">
          <div class="tamanho">
            <div class="bloqueado" v-if="curso.bloqueado">
              <Svgs nome="cadeado" />
            </div>
            <div class="imagem" :style="`background-image: url('${curso.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
          </div>
        </div>
        <div class="info">
          <div class="nome">
            <h3 :style="textoEstilo">{{ curso.nome }}</h3>
            <p :style="textoEstilo">{{ curso.descricao }}</p>
          </div>
          <div class="opcoes">
            <a class="opcao" :style="botaoEstilo" target="_blank" :href="curso.urlBloqueio" v-if="curso.bloqueado">
              <p>Liberar</p>
              <Svgs :style="svgBotaoEstilo" nome="cadeado" />
            </a>
            <button class="opcao" :style="botaoEstilo" v-else @click="irParaCurso(curso.hotlink)">
              <p>{{ tela.textoBotao || 'Começar' }}</p>
              <Svgs :style="svgBotaoEstilo" nome="seta" />
            </button>
          </div>
        </div>
        <div class="progresso">
          <div class="linha" :style="progressoEstiloFundo">
            <div class="atual" :style="{ ...progressoEstiloAtual, width: calcularPorcentagem(curso) }"></div>
          </div>
          <div class="aulas">
            <p :style="textoDoisEstilo">{{ tela.textoAulas || 'Aulas do curso' }}</p>
            <p class="porcentagem" :style="textoDoisEstilo">{{ calcularPorcentagem(curso, true) }}</p>
            <p :style="textoDoisEstilo">{{ curso.quantidadeAulasConcluidas }} / {{ curso.quantidadeAulas }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStoreAreas, useStoreCursos } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()

const tela = computed(() => storeAreas.area?.personalizacao?.telaCursosArea || {})

const estiloSombra = computed(() => ({
  backgroundImage: `linear-gradient(to top, ${tela.value.corFundo || '#E1E3EB'}, transparent)`
}))

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const quadroEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro || '#FFFFFF'
}))

const textoEstilo = computed(() => ({
  color: tela.value.corTextos || '#262836'
}))

const capaTextoEstilo = computed(() => ({
  color: tela.value.corTextoCapa || '#FFFFFF'
}))

const textoDoisEstilo = computed(() => ({
  color: tela.value.corTextosDois || '#262836'
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao || '#262836',
  color: tela.value.corTextoBotao || '#FFFFFF'
}))

const svgBotaoEstilo = computed(() => ({
  fill: tela.value.corTextoBotao || '#FFFFFF'
}))

const progressoEstiloFundo = computed(() => ({
  backgroundColor: tela.value.corProgressoFundo || '#E1E3EB'
}))

const progressoEstiloAtual = computed(() => ({
  backgroundColor: tela.value.corProgressoAtual || '#262836'
}))

function irParaCurso(hotlinkCurso) {
  if (dominioPersonalizado()) {
    window.location.assign(`/curso/${hotlinkCurso}`)
  } else {
    const hotlinkArea = storeAreas.area?.hotlink
    window.location.assign(`/${hotlinkArea}/curso/${hotlinkCurso}`)
  }
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function calcularPorcentagem(curso, texto = false) {
  if (!curso.quantidadeAulas || curso.quantidadeAulas === 0) return texto ? '0%' : '0%'
  const percentual = Math.round((curso.quantidadeAulasConcluidas / curso.quantidadeAulas) * 100)
  return texto ? `${percentual}%` : `${percentual}%`
}

function atualizarFonte() {
  const fontFamily = tela.value.fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watch(
  () => tela.value.corFundo,
  (novaCorFundo) => {
    document.body.style.backgroundColor = novaCorFundo || '#E1E3EB'
    document.documentElement.style.backgroundColor = novaCorFundo || '#E1E3EB'
  },
  { immediate: true }
)

watch(
  () => storeAreas.area?.personalizacao?.telaCursosArea,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.cursos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  background-color: #e1e3eb;
  position: relative;
}

.apresentacao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;
}

.apresentacao .sombra {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
}

.apresentacao .fundo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.apresentacao .fundo .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 33.33%;
}

.apresentacao .fundo.mobile .tamanho {
  padding-bottom: 66.66%;
}

.apresentacao .fundo .tamanho .imagem {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.apresentacao .fundo.desktop {
  display: flex;
}

.apresentacao .fundo.mobile {
  display: none;
}

.apresentacao .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 150px 20px 0 20px;
  z-index: 3;
}

.apresentacao .texto h2 {
  font-family: var(--fonte-tela);
  font-size: var(--f4);
  color: #ffffff;
  line-height: 1.2;
}

.apresentacao .texto p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.apresentacao .texto a {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  padding: 15px 30px;
  border-radius: 50px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.apresentacao .texto a:hover {
  opacity: 0.7;
  transform: scale(1.05);
}

section.cursos.dois .banner {
  padding: 0 30px 0 30px;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 30px 30px;
}

.banner img.desktop {
  display: flex;
}

.banner img.mobile {
  display: none;
}

.banner img {
  width: 100%;
  border-radius: 10px;
}

section.cursos.dois .lista {
  justify-content: flex-start;
  padding: 0 10px 30px 30px;
}

section.cursos.tres .lista {
  flex-direction: column;
}

section.cursos.quatro .lista {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-y: scroll;
  scrollbar-width: none;
}

section.cursos.quatro .lista::-webkit-scrollbar {
  display: none;
}

.lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 30px 30px;
}

section.cursos.dois .curso {
  width: calc(33.33% - 20px);
  margin: 0 20px 20px 0;
}

section.cursos.tres .curso {
  flex-direction: row;
  width: 100%;
  margin: 0 0 20px 0;
}

section.cursos.quatro .curso {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin: 0 20px 0 0;
}

.curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  width: calc(50% - 10px);
  margin: 0 0 20px 0;
  position: relative;
}

section.cursos.tres .curso .capa {
  max-width: 200px;
}

section.cursos.quatro .curso .capa {
  width: 100%;
}

section.cursos.um .curso .capa .tamanho {
  padding-bottom: 56.52%;
}

section.cursos.dois .curso .capa .tamanho {
  padding-bottom: 56.52%;
}

section.cursos.tres .curso .capa .tamanho {
  padding-bottom: 56.52%;
}

section.cursos.quatro .curso .capa .tamanho {
  padding-bottom: 156.52%;
}

section.cursos.quatro .curso .capa .tamanho .imagem {
  border-radius: 10px;
}

.curso .capa {
  width: 100%;
}

.curso .capa .tamanho {
  width: 100%;
  position: relative;
}

.curso .capa .tamanho .bloqueado {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000060;
  z-index: 1;
}

.curso .capa .tamanho .bloqueado svg {
  width: 30px;
  min-width: 30px;
  fill: #ffffff;
}

section.cursos.tres .curso .capa .tamanho .imagem {
  border-radius: 10px 0 0 10px;
}

.curso .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

section.cursos.quatro .curso .info {
  display: none;
}

section.cursos.dois .curso .info {
  flex-direction: column;
  justify-content: center;
}

section.cursos.dois .curso .info .nome {
  align-items: center;
}

section.cursos.tres .curso .info {
  padding: 20px;
}

.curso .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.curso .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.curso .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.curso .info .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 5px 0 0 0;
  line-height: 1.5;
}

section.cursos.dois .curso .info .opcoes {
  align-items: center;
  padding: 10px 0 0 0;
}

section.cursos.tres .curso .info .opcoes {
  padding: 0;
  position: absolute;
  right: 20px;
}

.curso .info .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 0 10px;
}

section.cursos.tres .curso .info .opcoes .opcao {
  margin: 0;
}

.curso .info .opcoes .opcao {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #262836;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #ffffff;
  margin: 0 0 0 10px;
  padding: 10px 15px;
  transition: all 0.3s;
}

.curso .info .opcoes .opcao:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.curso .info .opcoes .opcao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: #ffffff;
  margin: 0 0 0 5px;
}

section.cursos.dois .curso .info .opcoes .opcao {
  margin: 0;
}

section.cursos.tres .curso .progresso {
  padding: 20px 150px 20px 20px;
  max-width: 450px;
}

section.cursos.quatro .curso .progresso {
  display: none;
}

.curso .progresso {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 30px 30px 30px;
}

.curso .progresso .linha {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #e1e3eb;
  position: relative;
}

.curso .progresso .linha .atual {
  width: 10%;
  height: 5px;
  border-radius: 20px;
  background-color: #262836;
  position: absolute;
  left: 0;
}

section.cursos.dois .curso .progresso .aulas {
  flex-wrap: wrap;
}

.curso .progresso .aulas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.curso .progresso .aulas p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 10px 0 0 0;
}

.curso .progresso .aulas p.porcentagem {
  margin-left: auto;
  padding: 0 20px 0 0;
}

@media screen and (max-width: 1000px) {
  section.cursos {
    padding: 0;
  }

  section.cursos.dois {
    padding: 0;
  }

  .apresentacao .fundo.desktop {
    display: none;
  }

  .apresentacao .fundo.mobile {
    display: flex;
  }

  section.cursos .banner {
    padding: 0 20px 20px 20px;
  }

  section.cursos.dois .banner {
    padding: 0 20px 20px 20px;
  }

  .banner img.desktop {
    display: none;
  }

  .banner img.mobile {
    display: flex;
  }

  section.cursos.dois .curso {
    width: 100%;
    margin: 0 0 20px 0;
  }

  section.cursos.tres .curso {
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 20px;
  }

  section.cursos.um .lista {
    padding: 20px 20px 20px 20px;
  }

  section.cursos.dois .lista {
    padding: 20px 20px 20px 20px;
  }

  section.cursos.tres .lista {
    padding: 20px 20px 20px 20px;
  }

  section.cursos.quatro .lista {
    padding: 0 20px 20px 20px;
  }

  .curso {
    width: 100%;
    margin: 0 0 20px 0;
  }

  section.cursos.tres .curso .capa {
    max-width: 100%;
  }

  .curso .capa {
    width: 100%;
  }

  .curso .capa .tamanho {
    width: 100%;
    position: relative;
    padding-bottom: 35%;
  }

  section.cursos.tres .curso .capa .tamanho .imagem {
    border-radius: 10px;
  }

  .curso .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  section.cursos.dois .curso .info {
    flex-direction: column;
    justify-content: center;
  }

  section.cursos.dois .curso .info .nome {
    align-items: center;
  }

  section.cursos.tres .curso .info {
    padding: 20px 0 0 0;
  }

  .curso .info {
    padding: 20px;
  }

  section.cursos.um .curso .info {
    padding: 20px 130px 20px 20px;
  }

  .curso .info .nome h3 {
    font-size: var(--f2);
  }

  .curso .info .nome p {
    font-size: var(--f1);
  }

  section.cursos.dois .curso .info .opcoes {
    align-items: center;
    padding: 10px 0 0 0;
  }

  section.cursos.tres .curso .info .opcoes {
    padding: 0;
    position: absolute;
    right: 20px;
  }

  section.cursos.um .curso .info .opcoes {
    padding: 0;
    position: absolute;
    right: 20px;
  }

  .curso .info .opcoes {
    padding: 0 0 0 10px;
  }

  section.cursos.tres .curso .info .opcoes button {
    margin: 0;
  }

  section.cursos.dois .curso .info .opcoes button {
    margin: 0;
  }

  section.cursos.tres .curso .progresso {
    padding: 20px 0 0 0;
    max-width: 100%;
  }

  .curso .progresso {
    padding: 0 20px 20px 20px;
  }

  section.cursos.dois .curso .progresso .aulas {
    flex-wrap: wrap;
  }

  .curso .progresso .aulas p {
    font-size: var(--f1);
    margin: 8px 0 0 0;
  }

  .curso .progresso .aulas p.porcentagem {
    padding: 0 20px 0 0;
  }
}
</style>
