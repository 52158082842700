<template>
  <div id="loading">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import Tela from '@components/pages/loading/partials/Tela.vue'

const route = useRoute()

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function redirecionar() {
  if (dominioPersonalizado()) {
    setTimeout(() => {
      window.location.assign('/inicio')
    }, 2500)
  } else {
    const hotlink = route.params.hotlink
    setTimeout(() => {
      window.location.assign(`/${hotlink}/inicio`)
    }, 2500)
  }
}

onMounted(() => {
  redirecionar()
})
</script>

<style scoped></style>
