<template>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22" v-if="props.nome === 'excluir'">
    <path
      d="M16 6.00002C15.7348 6.00002 15.4804 6.10537 15.2929 6.29291C15.1054 6.48045 15 6.7348 15 7.00002V18.191C14.9713 18.6967 14.744 19.1706 14.3675 19.5094C13.991 19.8482 13.4959 20.0246 12.99 20H5.01C4.5041 20.0246 4.00898 19.8482 3.63251 19.5094C3.25603 19.1706 3.02869 18.6967 3 18.191V7.00002C3 6.7348 2.89464 6.48045 2.70711 6.29291C2.51957 6.10537 2.26522 6.00002 2 6.00002C1.73478 6.00002 1.48043 6.10537 1.29289 6.29291C1.10536 6.48045 1 6.7348 1 7.00002V18.191C1.02854 19.2272 1.46658 20.2099 2.21818 20.9239C2.96978 21.6378 3.97367 22.0247 5.01 22H12.99C14.0263 22.0247 15.0302 21.6378 15.7818 20.9239C16.5334 20.2099 16.9715 19.2272 17 18.191V7.00002C17 6.7348 16.8946 6.48045 16.7071 6.29291C16.5196 6.10537 16.2652 6.00002 16 6.00002Z" />
    <path
      d="M17 3H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0H6C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V3H1C0.734784 3 0.48043 3.10536 0.292893 3.29289C0.105357 3.48043 0 3.73478 0 4C0 4.26522 0.105357 4.51957 0.292893 4.70711C0.48043 4.89464 0.734784 5 1 5H17C17.2652 5 17.5196 4.89464 17.7071 4.70711C17.8946 4.51957 18 4.26522 18 4C18 3.73478 17.8946 3.48043 17.7071 3.29289C17.5196 3.10536 17.2652 3 17 3ZM7 3V2H11V3H7Z" />
    <path
      d="M8 16V9C8 8.73478 7.89464 8.48043 7.70711 8.29289C7.51957 8.10536 7.26522 8 7 8C6.73478 8 6.48043 8.10536 6.29289 8.29289C6.10536 8.48043 6 8.73478 6 9V16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17C7.26522 17 7.51957 16.8946 7.70711 16.7071C7.89464 16.5196 8 16.2652 8 16Z" />
    <path
      d="M12 16V9C12 8.73478 11.8946 8.48043 11.7071 8.29289C11.5196 8.10536 11.2652 8 11 8C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9V16C10 16.2652 10.1054 16.5196 10.2929 16.7071C10.4804 16.8946 10.7348 17 11 17C11.2652 17 11.5196 16.8946 11.7071 16.7071C11.8946 16.5196 12 16.2652 12 16Z" />
  </svg>

  <svg viewBox="0 0 568 338" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'logo-v2'">
    <path
      d="M567.061 336.065H552.98L522.421 307.902V336.065H512.534V272.15H542.494C556.375 272.15 565.763 279.041 565.763 291.425C565.763 304.107 557.174 310.399 543.193 310.399H537.9L567.061 336.065ZM522.421 281.138V301.811H543.193C550.583 301.811 555.676 298.515 555.676 291.425C555.676 284.534 550.583 281.138 543.193 281.138H522.421Z"
      fill="var(--cor-preto)" />
    <path
      d="M420.619 336.065V272.15H470.552V281.138H430.406V297.516H470.552V306.305H430.406V327.077H470.552V336.065H420.619Z"
      fill="var(--cor-preto)" />
    <path
      d="M324.9 336.065V272.15H355.059C368.441 272.15 375.831 277.843 375.831 288.229C375.831 295.619 371.637 299.913 366.843 301.511C373.035 302.909 379.027 307.902 379.027 318.288C379.027 330.172 371.837 336.065 358.155 336.065H324.9ZM334.387 297.516H355.059C361.95 297.516 366.144 294.52 366.144 289.427C366.144 283.735 362.25 281.138 354.76 281.138H334.387V297.516ZM334.387 327.077H357.855C365.345 327.077 369.34 323.681 369.34 316.79C369.44 310.099 365.146 306.305 357.156 306.305H334.387V327.077Z"
      fill="var(--cor-preto)" />
    <path
      d="M243.96 337.762L218.195 290.626V336.065H208.408V272.25H218.195L243.76 319.687L269.725 272.25H279.313V336.065H269.426V290.725L243.96 337.762Z"
      fill="var(--cor-preto)" />
    <path
      d="M116.492 336.065V272.15H166.425V281.138H126.279V297.516H166.425V306.305H126.279V327.077H166.425V336.065H116.492Z"
      fill="var(--cor-preto)" />
    <path
      d="M35.5523 337.762L9.78685 290.626V336.065H0V272.25H9.78685L35.3525 319.687L61.3177 272.25H70.9048V336.065H61.0181V290.725L35.5523 337.762Z"
      fill="var(--cor-preto)" />
    <path d="M441.965 225.008V161.094H451.852L451.752 216.02H487.803V225.008H441.965Z" fill="var(--cor-preto)" />
    <path
      d="M350.049 225.008V161.094H399.982V170.081H359.836V186.459H399.982V195.248H359.836V216.02L399.982 216.12V225.108L350.049 225.008Z"
      fill="var(--cor-preto)" />
    <path d="M279.887 227.704L245.033 161.094H256.019L279.787 208.53L303.455 161.193H314.141L279.887 227.704Z"
      fill="var(--cor-preto)" />
    <path
      d="M162.805 225.008V161.094H212.738V170.081H172.592V186.459L212.738 186.36V195.148L172.592 195.248V216.02L212.738 216.12V225.108L162.805 225.008Z"
      fill="var(--cor-preto)" />
    <path d="M78.106 225.008V161.094H87.9927L87.8928 216.02H123.944V225.008H78.106Z" fill="var(--cor-preto)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M333.171 33.3578L299.813 33.3578V0L333.171 2.91623e-06V33.3578ZM266.455 66.7157H299.813V33.358L266.455 33.358L266.455 66.7156H233.097L233.097 100.073H266.455H266.455H299.813V84.0617L266.455 84.0616L266.455 66.7157ZM317.159 66.7157H333.171V33.358H317.159V66.7155H299.813V84.0615H317.159L317.159 66.7157ZM333.171 100.073H317.159V84.0616L333.171 84.0617V100.073Z"
      fill="url(#paint0_linear_4_1231)" />
    <rect x="266.048" y="32.9509" width="32.951" height="32.951" rx="16.4755" transform="rotate(-180 266.048 32.9509)"
      fill="url(#paint1_linear_4_1231)" />
    <defs>
      <linearGradient id="paint0_linear_4_1231" x1="283.134" y1="0" x2="283.134" y2="100.073"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB628" />
        <stop offset="1" stop-color="#FF285C" />
      </linearGradient>
      <linearGradient id="paint1_linear_4_1231" x1="282.524" y1="65.9019" x2="282.524" y2="32.9509"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CD0FF" />
        <stop offset="1" stop-color="#5200FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg viewBox="0 0 805 177" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'logo-v3'">
    <path
      d="M804.061 175.065H789.98L759.421 146.902V175.065H749.534V111.15H779.494C793.375 111.15 802.763 118.041 802.763 130.425C802.763 143.107 794.174 149.399 780.193 149.399H774.9L804.061 175.065ZM759.421 120.138V140.811H780.193C787.583 140.811 792.676 137.515 792.676 130.425C792.676 123.534 787.583 120.138 780.193 120.138H759.421Z"
      fill="var(--cor-preto)" />
    <path
      d="M657.619 175.065V111.15H707.552V120.138H667.406V136.516H707.552V145.305H667.406V166.077H707.552V175.065H657.619Z"
      fill="var(--cor-preto)" />
    <path
      d="M561.9 175.065V111.15H592.059C605.441 111.15 612.831 116.843 612.831 127.229C612.831 134.619 608.637 138.913 603.843 140.511C610.035 141.909 616.027 146.902 616.027 157.288C616.027 169.172 608.837 175.065 595.155 175.065H561.9ZM571.387 136.516H592.059C598.95 136.516 603.144 133.52 603.144 128.427C603.144 122.735 599.25 120.138 591.76 120.138H571.387V136.516ZM571.387 166.077H594.855C602.345 166.077 606.34 162.681 606.34 155.79C606.44 149.099 602.146 145.305 594.156 145.305H571.387V166.077Z"
      fill="var(--cor-preto)" />
    <path
      d="M480.96 176.762L455.195 129.626V175.065H445.408V111.25H455.195L480.76 158.687L506.725 111.25H516.313V175.065H506.426V129.725L480.96 176.762Z"
      fill="var(--cor-preto)" />
    <path
      d="M353.492 175.065V111.15H403.425V120.138H363.279V136.516H403.425V145.305H363.279V166.077H403.425V175.065H353.492Z"
      fill="var(--cor-preto)" />
    <path
      d="M272.552 176.762L246.787 129.626V175.065H237V111.25H246.787L272.353 158.687L298.318 111.25H307.905V175.065H298.018V129.725L272.552 176.762Z"
      fill="var(--cor-preto)" />
    <path d="M600.859 64.0077V0.0935059H610.746L610.646 55.0197H646.697V64.0077H600.859Z" fill="var(--cor-preto)" />
    <path
      d="M508.943 64.0077V0.0935059H558.876V9.08144H518.73V25.4594H558.876V34.2476H518.73V55.0197L558.876 55.1196V64.1075L508.943 64.0077Z"
      fill="var(--cor-preto)" />
    <path d="M438.781 66.7041L403.927 0.0935059H414.913L438.681 47.5298L462.349 0.193372H473.035L438.781 66.7041Z"
      fill="var(--cor-preto)" />
    <path
      d="M321.699 64.0077V0.0935059H371.632V9.08144H331.486V25.4594L371.632 25.3596V34.1478L331.486 34.2476V55.0197L371.632 55.1196V64.1075L321.699 64.0077Z"
      fill="var(--cor-preto)" />
    <path d="M237 64.0077V0.0935059H246.887L246.787 55.0197H282.838V64.0077H237Z" fill="var(--cor-preto)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M175 58.3331L116.667 58.3331V-0.000244141L175 -0.000239041V58.3331ZM58.3336 116.667L58.3336 147H116.667V116.667L58.3336 116.667ZM58.3336 175H58.3334H0.000244141L0.00024924 116.667L58.3334 116.667L58.3334 58.3334L116.667 58.3334L116.667 116.667H147V58.3334L175 58.3334V116.667H147V147H116.667V175H58.3336ZM147 147H175V175H147V147Z"
      fill="url(#paint0_linear_4_1230)" />
    <rect x="57.6218" y="57.6218" width="57.622" height="57.6219" rx="28.811" transform="rotate(-180 57.6218 57.6218)"
      fill="url(#paint1_linear_4_1230)" />
    <defs>
      <linearGradient id="paint0_linear_4_1230" x1="87.5001" y1="-0.000244141" x2="87.5001" y2="175"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB628" />
        <stop offset="1" stop-color="#FF285C" />
      </linearGradient>
      <linearGradient id="paint1_linear_4_1230" x1="86.4328" y1="115.244" x2="86.4328" y2="57.6218"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CD0FF" />
        <stop offset="1" stop-color="#5200FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg viewBox="0 0 958 82" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'logo-v1'">
    <path
      d="M957.208 66.3712H945.67L920.63 43.2952V66.3712H912.529V14H937.078C948.452 14 956.144 19.6463 956.144 29.7932C956.144 40.1856 949.107 45.3409 937.651 45.3409H933.314L957.208 66.3712ZM920.63 21.3647V38.3035H937.651C943.706 38.3035 947.88 35.6031 947.88 29.7932C947.88 24.1469 943.706 21.3647 937.651 21.3647H920.63Z"
      fill="var(--cor-preto)" />
    <path
      d="M837.214 66.3712V14H878.129V21.3647H845.233V34.7848H878.129V41.9859H845.233V59.0065H878.129V66.3712H837.214Z"
      fill="var(--cor-preto)" />
    <path
      d="M758.781 66.3712V14H783.494C794.459 14 800.515 18.6643 800.515 27.1746C800.515 33.2301 797.078 36.7488 793.15 38.058C798.223 39.2037 803.133 43.2952 803.133 51.8055C803.133 61.5433 797.241 66.3712 786.031 66.3712H758.781ZM766.555 34.7848H783.494C789.14 34.7848 792.577 32.3299 792.577 28.1566C792.577 23.4923 789.386 21.3647 783.249 21.3647H766.555V34.7848ZM766.555 59.0065H785.785C791.923 59.0065 795.196 56.2243 795.196 50.578C795.278 45.0954 791.759 41.9859 785.212 41.9859H766.555V59.0065Z"
      fill="var(--cor-preto)" />
    <path
      d="M692.459 67.7624L671.347 29.1386V66.3713H663.328V14.0818H671.347L692.296 52.9511L713.572 14.0818H721.427V66.3713H713.326V29.2204L692.459 67.7624Z"
      fill="var(--cor-preto)" />
    <path
      d="M588.012 66.3712V14H628.927V21.3647H596.032V34.7848H628.927V41.9859H596.032V59.0065H628.927V66.3712H588.012Z"
      fill="var(--cor-preto)" />
    <path
      d="M521.69 67.7624L500.578 29.1386V66.3713H492.559V14.0818H500.578L521.527 52.9511L542.802 14.0818H550.658V66.3713H542.557V29.2204L521.69 67.7624Z"
      fill="var(--cor-preto)" />
    <path d="M423.157 66.3712V14H431.258L431.176 59.0065H460.717V66.3712H423.157Z" fill="var(--cor-preto)" />
    <path
      d="M347.841 66.3712V14H388.756V21.3647H355.86V34.7848H388.756V41.9859H355.86V59.0065L388.756 59.0884V66.4531L347.841 66.3712Z"
      fill="var(--cor-preto)" />
    <path d="M290.35 68.5807L261.791 14H270.792L290.268 52.8693L309.662 14.0818H318.417L290.35 68.5807Z"
      fill="var(--cor-preto)" />
    <path
      d="M194.413 66.3712V14H235.328V21.3647H202.432V34.7848L235.328 34.703V41.9041L202.432 41.9859V59.0065L235.328 59.0884V66.4531L194.413 66.3712Z"
      fill="var(--cor-preto)" />
    <path d="M125.011 66.3712V14H133.112L133.03 59.0065H162.571V66.3712H125.011Z" fill="var(--cor-preto)" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M68.88 27.3333L54.6667 27.3333L54.6667 0L82 2.38956e-06V27.3333V54.6667H68.88V27.3333ZM68.88 68.88V54.6667H54.6667L54.6667 27.3333H27.3333L27.3333 54.6667H2.38956e-06L0 82H27.3333H54.6667V68.88L68.88 68.88ZM68.88 68.88V82H82V68.88L68.88 68.88ZM27.3333 68.88V54.6667L54.6667 54.6667V68.88L27.3333 68.88Z"
      fill="url(#paint0_linear_4_1464)" />
    <rect x="27" y="27" width="27" height="27" rx="12.5" transform="rotate(-180 27 27)"
      fill="url(#paint1_linear_4_1464)" />
    <defs>
      <linearGradient id="paint0_linear_4_1464" x1="41" y1="0" x2="41" y2="82" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB628" />
        <stop offset="1" stop-color="#FF285C" />
      </linearGradient>
      <linearGradient id="paint1_linear_4_1464" x1="40.5" y1="54" x2="40.5" y2="27" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CD0FF" />
        <stop offset="1" stop-color="#5200FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16" v-else-if="props.nome === 'visualizar'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.5 1.72727C5.75524 1.72727 2.59245 4.25818 1.74291 7.6348C1.73155 7.67997 1.72585 7.72635 1.72585 7.77273C1.72585 7.8191 1.73155 7.8654 1.74291 7.91065C2.59244 11.2873 5.75523 13.8182 9.5 13.8182C13.2448 13.8182 16.4075 11.2873 17.2571 7.91065C17.2684 7.86548 17.2741 7.8191 17.2741 7.77273C17.2741 7.72635 17.2684 7.68006 17.2571 7.6348C16.4075 4.25819 13.2448 1.72727 9.5 1.72727ZM0.0693068 7.21309C1.11525 3.05574 4.978 0 9.5 0C14.022 0 17.8848 3.05576 18.9307 7.21309C18.9769 7.39679 19 7.5848 19 7.77273C19 7.96065 18.9769 8.14867 18.9307 8.33236C17.8848 12.4897 14.022 15.5455 9.5 15.5455C4.978 15.5455 1.11523 12.4897 0.0693068 8.33236C0.0230937 8.14867 0 7.96065 0 7.77273C-1.72718e-06 7.5848 0.023085 7.39679 0.0693068 7.21309Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.49994 3.45454C7.11508 3.45454 5.18176 5.38786 5.18176 7.77273C5.18176 10.1576 7.11508 12.0909 9.49994 12.0909C11.8848 12.0909 13.8181 10.1576 13.8181 7.77273C13.8181 5.38786 11.8848 3.45454 9.49994 3.45454ZM9.49994 10.3636C10.9309 10.3636 12.0909 9.20369 12.0909 7.77273C12.0909 6.34177 10.9309 5.18182 9.49994 5.18182C8.06899 5.18182 6.90904 6.34177 6.90904 7.77273C6.90904 9.20369 8.06899 10.3636 9.49994 10.3636Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" v-else-if="props.nome === 'editar'">
    <path
      d="M11.6928 2.98933L15.3545 6.6509L6.08591 15.9194L2.42638 12.2579L11.6928 2.98933ZM18.1329 2.10624L16.5 0.473305C15.8689 -0.157768 14.8442 -0.157768 14.2109 0.473305L12.6468 2.03749L16.3084 5.6991L18.1329 3.87456C18.6224 3.38506 18.6224 2.5957 18.1329 2.10624ZM0.510189 17.4045C0.443553 17.7044 0.714319 17.9731 1.01425 17.9002L5.0945 16.9109L1.43497 13.2493L0.510189 17.4045Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" v-else-if="props.nome === 'coracao'">
    <path
      d="m198 32.5c-3.4-1.1-7-1.8-10.7-2.2-47.2-4.8-59.3 40.2-59.3 40.2s-12.1-45-59.2-40.2c-43.8 4.4-66.8 59.6-38.1 106 8.2 13.3 21 27.6 34.7 40.9 27.5 26.7 58.5 48.9 62.6 48.9 6.2 0 72.5-49.7 97.3-89.7 26.3-42.5 9.3-92.4-27.3-103.9z" />
    <path
      d="m225.3 136.3c26.3-42.4 9.3-92.3-27.3-103.8 0 0 36.5 35.2 15.5 71.4s-91.2 81.2-101.1 82.8c-8.2 1.3-29-.6-47-9.4 27.5 26.7 58.5 48.9 62.6 48.9 6.2-.1 72.5-49.8 97.3-89.9z" />
    <ellipse cx="50.6" cy="65.5" opacity=".3" rx="24.9" ry="12.6"
      transform="matrix(.645 -.764 .764 .645 -32.075 61.903)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23" v-else-if="props.nome === 'cursor'">
    <path
      d="M19.9963 11.6L19.9929 11.6001V11.3492C19.9929 10.3543 19.1863 9.54773 18.1915 9.54773C17.1966 9.54773 16.39 10.3543 16.39 11.3492V9.36758C16.39 8.3727 15.5834 7.56613 14.5885 7.56613C13.5937 7.56613 12.7871 8.3727 12.7871 9.36758V7.56613C12.7871 6.57124 11.9205 5.76467 10.9256 5.76467C9.93069 5.76467 9.06839 6.63129 9.06839 7.62617L9.06407 1.80146C9.06407 0.806573 8.25749 0 7.26261 0C6.26772 0 5.46115 0.806573 5.46115 1.80146V13.4809C5.46115 13.4809 3.61814 7.94647 1.02512 9.26502C0.118625 9.72596 -0.260522 10.8199 0.190083 11.7318L3.54212 18.5154C3.54212 18.5154 5.61403 22.5878 9.2274 22.5878H14.0313C17.3477 22.5878 20 19.8993 20 16.5829L19.9963 11.6Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" v-else-if="props.nome === 'template'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.16667 7.49999C9.16667 7.03999 8.79333 6.66666 8.33333 6.66666H0.833333C0.373333 6.66666 0 7.03999 0 7.49999V19.1667C0 19.6267 0.373333 20 0.833333 20H8.33333C8.79333 20 9.16667 19.6267 9.16667 19.1667V7.49999Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M20 15.8333C20 15.3733 19.6267 15 19.1667 15H11.6667C11.2067 15 10.8334 15.3733 10.8334 15.8333V19.1667C10.8334 19.6267 11.2067 20 11.6667 20H19.1667C19.6267 20 20 19.6267 20 19.1667V15.8333Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.16667 0.833333C9.16667 0.373333 8.79333 0 8.33333 0H0.833333C0.373333 0 0 0.373333 0 0.833333V4.16667C0 4.62667 0.373333 5 0.833333 5H8.33333C8.79333 5 9.16667 4.62667 9.16667 4.16667V0.833333Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M20 0.833333C20 0.373333 19.6267 0 19.1667 0H11.6667C11.2067 0 10.8334 0.373333 10.8334 0.833333V12.5C10.8334 12.96 11.2067 13.3333 11.6667 13.3333H19.1667C19.6267 13.3333 20 12.96 20 12.5V0.833333Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 512 512" v-else-if="props.nome === 'html'">
    <path
      d="m31.859375 448.058594 194.253906 63.941406 194.253907-63.941406 31.859374-448.058594h-452.226562zm339.757813-373.058594-4.277344 60h-222.246094l5.417969 77h211.480469l-12.878907 180.601562-123 41.101563-123.003906-41.101563-4.203125-60.601562h60l1.214844 16.492188 65.992187 21.902343 66.003907-21.898437 5.390624-76.496094h-203.097656l-13.800781-197zm0 0" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else-if="props.nome === 'codigo'">
    <path
      d="m512 121v-75c0-24.813-20.187-45-45-45h-422c-24.813 0-45 20.187-45 45v75zm-166-60h90c8.284 0 15 6.716 15 15s-6.716 15-15 15h-90c-8.284 0-15-6.716-15-15s6.716-15 15-15zm-150 0c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-60 0c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-60 0c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15z" />
    <path
      d="m0 151v315c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-315zm175.37 213.287c6.47 5.175 7.518 14.614 2.343 21.083-5.174 6.468-14.615 7.518-21.083 2.342l-75-60c-7.499-5.997-7.504-17.424 0-23.426l75-60c6.466-5.175 15.907-4.127 21.083 2.342 5.175 6.469 4.127 15.909-2.343 21.083l-60.358 48.289zm139.417-147.378-90 210c-3.263 7.613-12.079 11.143-19.696 7.878-7.614-3.263-11.142-12.082-7.878-19.696l90-210c3.264-7.614 12.082-11.141 19.696-7.878s11.142 12.081 7.878 19.696zm115.583 110.804-75 60c-6.458 5.168-15.9 4.136-21.083-2.342-5.175-6.469-4.127-15.909 2.343-21.083l60.358-48.288-60.358-48.287c-6.47-5.175-7.518-14.614-2.343-21.083 5.174-6.469 14.614-7.518 21.083-2.342l75 60c7.499 5.997 7.504 17.423 0 23.425z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" v-else-if="props.nome === 'link'">
    <path
      d="M11.998 1.37297L7.93286 5.43806C7.92501 5.44587 7.92013 5.45536 7.91231 5.46326C8.91376 5.3174 9.94255 5.4174 10.8962 5.78927L13.6552 3.03028C14.5692 2.11626 16.0558 2.11626 16.9698 3.03028C17.8838 3.94423 17.8838 5.43083 16.9698 6.34478C16.8139 6.50072 12.6697 10.645 12.9047 10.4099C11.9835 11.3312 10.472 11.2918 9.59013 10.4099C9.13337 9.95311 8.3897 9.95311 7.93286 10.4099L7.22137 11.1214C7.41872 11.4567 7.64497 11.7793 7.93286 12.0672C9.6688 13.8032 12.6569 13.9418 14.5368 12.0878C14.5447 12.08 14.5541 12.075 14.562 12.0672L18.6271 8.00213C20.4577 6.1715 20.4577 3.2036 18.6271 1.37297C16.7965 -0.457657 13.8286 -0.457657 11.998 1.37297Z" />
    <path
      d="M9.11269 14.2019L6.34479 16.9698C5.43084 17.8838 3.94424 17.8838 3.03029 16.9698C2.11627 16.0558 2.11627 14.5693 3.03029 13.6552C3.18615 13.4994 7.33929 9.34623 7.10425 9.58127C8.0255 8.66002 9.53691 8.69936 10.4188 9.58127C10.8756 10.0381 11.6193 10.0381 12.0761 9.58127L12.7876 8.86979C12.5902 8.53447 12.364 8.21182 12.0761 7.924C10.3435 6.19131 7.35761 6.04388 5.47213 7.90342C5.46428 7.91123 5.45483 7.91615 5.44694 7.924L1.37294 11.998C-0.457608 13.8286 -0.457687 16.7965 1.37294 18.6272C3.20357 20.4577 6.17155 20.4577 8.0021 18.6272L12.0761 14.5531C12.0839 14.5453 12.0888 14.5359 12.0966 14.5279C11.0952 14.6738 10.0664 14.5738 9.11269 14.2019Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" v-else-if="props.nome === 'favicon'">
    <path
      d="M9.50005 0C4.53739 0 0.5 4.03739 0.5 8.99991C0.5 13.9625 4.53739 17.9998 9.50005 17.9998C14.4626 17.9998 18.5 13.9625 18.5 8.99995C18.5 4.03739 14.4626 0 9.50005 0ZM9.50005 15.5115C5.90958 15.5115 2.98849 12.5904 2.98849 8.99991C2.98849 5.40943 5.90958 2.48834 9.50005 2.48834C13.0905 2.48834 16.0115 5.40943 16.0115 8.99991C16.0115 12.5904 13.0904 15.5115 9.50005 15.5115Z" />
    <path
      d="M9.50002 10.6895C10.4332 10.6895 11.1896 9.93304 11.1896 8.9999C11.1896 8.06676 10.4332 7.3103 9.50002 7.3103C8.56688 7.3103 7.81042 8.06676 7.81042 8.9999C7.81042 9.93304 8.56688 10.6895 9.50002 10.6895Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" v-else-if="props.nome === 'facebook-colorido'">
    <path
      d="M18.5 9C18.5 13.4923 15.2087 17.2157 10.9062 17.8907V11.6016H13.0033L13.4023 9H10.9062V7.3118C10.9062 6.59988 11.255 5.90625 12.373 5.90625H13.5078V3.69141C13.5078 3.69141 12.4777 3.51562 11.493 3.51562C9.43742 3.51562 8.09375 4.76156 8.09375 7.01719V9H5.80859V11.6016H8.09375V17.8907C3.79133 17.2157 0.5 13.4923 0.5 9C0.5 4.02961 4.52961 0 9.5 0C14.4704 0 18.5 4.02961 18.5 9Z"
      fill="#1877F2" />
    <path
      d="M13.0033 11.6016L13.4023 9H10.9062V7.31176C10.9062 6.60002 11.2549 5.90625 12.3729 5.90625H13.5078V3.69141C13.5078 3.69141 12.4779 3.51562 11.4932 3.51562C9.43739 3.51562 8.09375 4.76156 8.09375 7.01719V9H5.80859V11.6016H8.09375V17.8906C8.55198 17.9625 9.02159 18 9.5 18C9.97841 18 10.448 17.9625 10.9062 17.8906V11.6016H13.0033Z"
      fill="#ffffff" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" v-else-if="props.nome === 'analytics-colorido'">
    <path
      d="M12.3125 5.625H6.6875C6.377 5.625 6.125 5.877 6.125 6.1875V17.4375C6.125 17.748 6.377 18 6.6875 18H12.3125C12.623 18 12.875 17.748 12.875 17.4375V6.1875C12.875 5.877 12.623 5.625 12.3125 5.625Z"
      fill="#FFC107" />
    <path
      d="M6.6875 11.25H1.0625C0.752 11.25 0.5 11.502 0.5 11.8125V17.4375C0.5 17.748 0.752 18 1.0625 18H6.6875C6.998 18 7.25 17.748 7.25 17.4375V11.8125C7.25 11.502 6.998 11.25 6.6875 11.25Z"
      fill="#FFC107" />
    <path
      d="M17.9375 0H12.3125C12.002 0 11.75 0.252 11.75 0.5625V17.4375C11.75 17.748 12.002 18 12.3125 18H17.9375C18.248 18 18.5 17.748 18.5 17.4375V0.5625C18.5 0.252 18.248 0 17.9375 0Z"
      fill="#FFA000" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24" v-else-if="props.nome === 'hotmart-colorido'">
    <path
      d="M18.4801 15.1359C18.5928 13.02 17.817 9.55999 15.9736 6.72933C15.9239 6.65659 15.841 6.68505 15.8576 6.76096C15.9802 7.19109 16.0101 7.97229 15.2939 7.92485C14.0208 7.84262 15.337 5.24284 12.5421 3.44008C12.5317 3.4307 12.5181 3.4252 12.5038 3.42458C12.4895 3.42396 12.4755 3.42825 12.4642 3.43668C12.453 3.44511 12.4453 3.45712 12.4425 3.47052C12.4398 3.48391 12.4422 3.4978 12.4493 3.50966C12.6416 3.85756 12.7543 4.94238 12.3133 5.31242C11.9586 5.60972 11.3187 5.53065 10.6788 4.55336C10.2532 3.87924 10.0336 3.10507 10.0448 2.31822C10.0559 1.53137 10.2973 0.763148 10.7418 0.10022C10.7948 0.017989 10.7219 -0.0136394 10.6622 0.00533704C6.81628 1.21667 6.04709 5.5433 5.22817 7.11835C5.09224 7.38402 4.97288 7.52002 4.73748 7.50737C4.03792 7.46309 4.54187 6.03669 4.7839 5.54014C4.78918 5.52809 4.78996 5.51467 4.78611 5.50214C4.78225 5.48961 4.774 5.47872 4.76272 5.4713C4.75145 5.46389 4.73785 5.46039 4.7242 5.46141C4.71055 5.46243 4.69768 5.46789 4.68775 5.47688C2.44298 7.83016 1.00716 10.7825 0.57325 13.9372C0.57325 13.8645 0.540095 14.1966 0.520202 14.4401C0.520202 14.535 0.513571 14.6309 0.50031 14.7279C0.50031 14.8418 0.50031 14.9556 0.50031 15.0727C0.480525 17.3496 1.40975 19.5408 3.08355 21.1642C4.75736 22.7876 7.03865 23.7102 9.42556 23.7291C11.8125 23.748 14.1095 22.8615 15.8113 21.2648C17.513 19.6681 18.4802 17.4919 18.5 15.215C18.4834 15.1865 18.4801 15.1612 18.4801 15.1359ZM9.44877 19.1241C8.62265 19.1197 7.81648 18.8816 7.13234 18.4398C6.4482 17.9981 5.91686 17.3726 5.60558 16.6426C5.29431 15.9126 5.21711 15.1109 5.38377 14.339C5.55042 13.5672 5.95342 12.8598 6.54175 12.3066C7.13008 11.7533 7.87727 11.3791 8.68872 11.2312C9.50017 11.0833 10.3394 11.1684 11.1001 11.4757C11.8608 11.7831 12.5088 12.2988 12.962 12.9577C13.4153 13.6166 13.6534 14.389 13.6462 15.177C13.6365 16.2309 13.1893 17.2381 12.4025 17.978C11.6157 18.7179 10.5535 19.13 9.44877 19.1241Z"
      fill="#F04E23" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" v-else-if="props.nome === 'kiwify-colorido'">
    <path
      d="M2.64904 0.72937C3.16783 1.32824 3.58772 1.8105 4.05782 2.35263C2.54863 4.01687 1.9127 6.07669 2.21241 8.38708C2.42997 10.0592 3.18913 11.4933 4.40013 12.6533C6.66088 14.8187 10.6834 15.5909 14.1353 12.5839C14.5644 13.0662 14.9964 13.5547 15.5 14.1221C13.5922 16.0117 11.2524 16.9352 8.60518 16.6941C4.39861 16.3096 1.53388 13.9739 0.366996 9.77552C-0.559514 6.43128 0.292449 3.4243 2.64904 0.72937Z"
      fill="#02AF6B" />
    <path
      d="M7.32581 9.50446C7.31821 10.1648 6.3704 11.1419 5.88508 11.0899C5.74816 11.0757 5.51387 10.6943 5.53365 10.6723C5.95202 10.1869 6.38561 9.71407 6.85723 9.2854C6.91657 9.23181 7.20563 9.44457 7.32581 9.50446Z"
      fill="#02AF6B" />
    <path
      d="M7.87493 11.5706C8.11379 11.0741 8.30396 10.6455 8.53368 10.2404C8.56563 10.1837 8.91707 10.2341 8.91859 10.2578C8.97184 10.7495 9.06616 11.2554 8.99618 11.7345C8.95662 12.004 8.63105 12.2278 8.43327 12.4705C8.26288 12.1962 8.09097 11.9204 7.87341 11.5706H7.87493Z"
      fill="#02AF6B" />
    <path
      d="M6.46638 7.79136C6.08604 8.41072 5.03022 8.76059 4.45666 8.40442C4.34104 8.33192 4.26193 7.93635 4.34256 7.83233C4.7868 7.25237 5.905 7.25395 6.46638 7.79136Z"
      fill="#02AF6B" />
    <path
      d="M4.64971 4.99241C5.40888 4.72607 6.23954 4.9798 6.60923 5.56134C6.8633 5.96006 6.72942 6.20907 6.29735 6.25635C5.68576 6.32254 4.89922 5.71579 4.64819 4.99241H4.64971Z"
      fill="#02AF6B" />
    <path
      d="M10.2941 10.2389C10.3534 10.1774 10.4858 9.91578 10.6516 9.89057C11.038 9.83068 11.7196 10.833 11.6831 11.435C11.6724 11.6084 11.4275 11.8921 11.2693 11.9078C10.8509 11.9504 10.3032 11.0615 10.2941 10.2389Z"
      fill="#02AF6B" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" v-else-if="props.nome === 'subir'">
    <path
      d="M17.4308 9.61378C17.3677 9.7551 17.2651 9.87517 17.1354 9.95954C17.0057 10.0439 16.8543 10.089 16.6996 10.0893H13.1034V14.4846C13.1034 14.5896 13.0828 14.6935 13.0426 14.7905C13.0025 14.8874 12.9436 14.9755 12.8694 15.0498C12.7952 15.124 12.7071 15.1828 12.6101 15.223C12.5131 15.2631 12.4092 15.2838 12.3042 15.2838H5.69526C5.5903 15.2838 5.48637 15.2631 5.3894 15.223C5.29243 15.1828 5.20432 15.124 5.13011 15.0498C5.05589 14.9755 4.99703 14.8874 4.95688 14.7905C4.91673 14.6935 4.89608 14.5896 4.89611 14.4846V10.0893H1.29993C1.1449 10.0893 0.993199 10.0442 0.863294 9.95961C0.73339 9.87498 0.630877 9.75443 0.568225 9.61262C0.505573 9.4708 0.485482 9.31384 0.510397 9.16082C0.535312 9.0078 0.604158 8.86531 0.708562 8.7507L8.40838 0.251735C8.48493 0.172134 8.57677 0.108808 8.67839 0.0655523C8.78 0.0222961 8.88931 0 8.99975 0C9.11019 0 9.21949 0.0222961 9.32111 0.0655523C9.42272 0.108808 9.51456 0.172134 9.59112 0.251735L17.2909 8.7507C17.3958 8.86529 17.4649 9.00803 17.4897 9.16136C17.5146 9.31469 17.4941 9.47194 17.4308 9.61378Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.004 492.004" v-else-if="props.nome === 'setinha'">
    <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
			c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
			c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
			c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"></path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40" v-else-if="props.nome === 'cadeado'">
    <path
      d="M15 0C12.5136 0 10.129 0.98772 8.37087 2.74587C6.61272 4.50403 5.625 6.8886 5.625 9.375V14.775C4.57514 15.1718 3.61326 15.7706 2.79375 16.5375C0.20625 19.1062 0 24.6187 0 26.25C0 27.8813 0.20625 33.3938 2.79375 35.9625C5.08125 38.2313 10.0312 39.375 15 39.375C19.9688 39.375 24.9188 38.25 27.2063 35.9813C29.7938 33.3938 30 27.8813 30 26.25C30 24.6187 29.7938 19.1062 27.2063 16.5375C26.3867 15.7706 25.4249 15.1718 24.375 14.775V9.375C24.375 6.8886 23.3873 4.50403 21.6291 2.74587C19.871 0.98772 17.4864 0 15 0ZM9.375 9.375C9.375 7.88316 9.96763 6.45242 11.0225 5.39752C12.0774 4.34263 13.5082 3.75 15 3.75C16.4918 3.75 17.9226 4.34263 18.9775 5.39752C20.0324 6.45242 20.625 7.88316 20.625 9.375V13.65C16.9054 12.9749 13.0946 12.9749 9.375 13.65V9.375ZM16.875 26.25V28.125C16.875 28.6223 16.6775 29.0992 16.3258 29.4508C15.9742 29.8025 15.4973 30 15 30C14.5027 30 14.0258 29.8025 13.6742 29.4508C13.3225 29.0992 13.125 28.6223 13.125 28.125V26.25C12.6277 26.25 12.1508 26.0525 11.7992 25.7008C11.4475 25.3492 11.25 24.8723 11.25 24.375C11.25 23.8777 11.4475 23.4008 11.7992 23.0492C12.1508 22.6975 12.6277 22.5 13.125 22.5H16.875C17.3723 22.5 17.8492 22.6975 18.2008 23.0492C18.5525 23.4008 18.75 23.8777 18.75 24.375C18.75 24.8723 18.5525 25.3492 18.2008 25.7008C17.8492 26.0525 17.3723 26.25 16.875 26.25Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 34" v-else-if="props.nome === 'usuario'">
    <path
      d="M15.0038 16.2961C19.5038 16.2961 23.1518 12.6481 23.1518 8.14806C23.1518 3.64801 19.5038 0 15.0038 0C10.5037 0 6.85571 3.64801 6.85571 8.14806C6.85571 12.6481 10.5037 16.2961 15.0038 16.2961Z" />
    <path
      d="M29.5326 25.8508L29.3096 25.3019C28.5602 23.4191 27.2716 21.7994 25.6055 20.6459C23.9393 19.4924 21.9697 18.8565 19.9436 18.8177H10.0802C8.05408 18.8565 6.08449 19.4924 4.41834 20.6459C2.7522 21.7994 1.46364 23.4191 0.714211 25.3019L0.474057 25.8337C0.09997 26.6922 -0.0566066 27.6298 0.0181964 28.5634C0.0929994 29.4969 0.396873 30.3976 0.902902 31.1856C1.31496 31.8586 1.8921 32.415 2.57958 32.8023C3.26705 33.1896 4.04208 33.3948 4.83112 33.3985H25.1584C25.9497 33.3945 26.727 33.1893 27.4172 32.8022C28.1073 32.4151 28.6877 31.8588 29.1037 31.1856C29.6055 30.399 29.9069 29.5015 29.9816 28.5716C30.0564 27.6416 29.9022 26.7074 29.5326 25.8508Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" v-else-if="props.nome === 'sair'">
    <path
      d="m500 860a376.6 376.6 0 0 1 -252.69-656.15 60 60 0 0 1 80.46 89 257 257 0 0 0 -84.54 190.35c0 141.61 115.19 256.8 256.77 256.8s256.77-115.19 256.77-256.77a257 257 0 0 0 -84.54-190.35 60 60 0 0 1 80.46-89 376.65 376.65 0 0 1 -252.69 656.12z">
    </path>
    <path d="m500 461.93a60 60 0 0 1 -60-60v-201.93a60 60 0 0 1 120 0v201.93a60 60 0 0 1 -60 60z"></path>
  </svg>

  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'girar'">
    <path
      d="M8.527 5.219L3.714 8.233c-.822.515-1.172 1.534-.839 2.445s1.256 1.466 2.216 1.33l2.546-.359c.093-.013.186.024.244.098l1.593 2.018c.601.761 1.652.998 2.522.569s1.32-1.408 1.082-2.348l-1.399-5.504a2.1 2.1 0 0 0-3.152-1.263z" />
    <path
      d="M10.219 12.924a.75.75 0 0 0-.099-.763l-1.062-1.344a1.76 1.76 0 0 0-1.63-.653l-1.713.241a.75.75 0 0 0-.589.458c-.269.654-.485 1.335-.642 2.038-.204.917-.311 1.87-.305 2.849.024 3.504 1.453 6.678 3.771 8.956 2.306 2.267 5.474 3.663 8.96 3.627h.004a12.49 12.49 0 0 0 8.811-3.782c2.211-2.28 3.576-5.393 3.523-8.817-.058-3.391-1.46-6.447-3.721-8.632-2.236-2.16-5.284-3.491-8.634-3.434a2.08 2.08 0 0 0-2.068 2.077 2.08 2.08 0 0 0 2.066 2.078c2.168.037 4.11.961 5.508 2.407a7.7 7.7 0 0 1 2.157 5.502v.002c-.032 2.095-.925 3.975-2.321 5.328-1.373 1.332-3.259 2.127-5.321 2.095-2.039-.021-3.867-.878-5.19-2.225-1.311-1.333-2.112-3.166-2.098-5.182.003-.56.072-1.104.195-1.627.098-.415.232-.815.398-1.199z" />
  </svg>

  <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'zoom-in'">
    <path
      d="M364.8 299.55c46.3-75.8 36.9-176.3-28.6-241.9-76.8-76.8-201.8-76.8-278.6 0s-76.8 201.8 0 278.5c65.5 65.5 166 74.9 241.9 28.6L412 477.25c18 18 47.3 18 65.3 0s18-47.3 0-65.3l-112.5-112.4zm-69.3-4c-54.4 54.4-142.8 54.4-197.1 0-54.4-54.4-54.4-142.8 0-197.1 54.4-54.4 142.8-54.4 197.1 0 54.3 54.3 54.3 142.7 0 197.1zM220 171.95h59.4v45.3H220v59.4h-45.3v-59.4h-59.3v-45.3h59.4v-59.4h45.3v59.4h-.1z" />
  </svg>

  <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'zoom-out'">
    <path
      d="M364.8 299.5c46.3-75.8 36.9-176.3-28.6-241.9-76.8-76.8-201.8-76.8-278.6 0s-76.8 201.8 0 278.6c65.5 65.5 166 74.9 241.9 28.6L412 477.3c18 18 47.3 18 65.3 0s18-47.3 0-65.3L364.8 299.5zm-69.3-4c-54.4 54.4-142.8 54.4-197.1 0C44 241.1 44 152.7 98.4 98.4c54.4-54.4 142.8-54.4 197.1 0 54.3 54.3 54.3 142.7 0 197.1zM128 167.3h138.7v54.6H128v-54.6z" />
  </svg>

  <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'desfazer'">
    <path
      d="M483.582 442.704C444.427 364.364 368.123 270.995 227 270.995h-15v75c0 5.537-3.047 10.62-7.925 13.228-4.863 2.622-10.796 2.3-15.396-.747l-181.994-120c-3.94-2.622-6.401-6.958-6.665-11.675-.249-4.731 1.743-9.302 5.376-12.334l181.994-150c4.497-3.706 10.737-4.512 15.967-2.051A14.99 14.99 0 0 1 212 75.995v75h15c157.148 0 285 127.852 285 285a15.01 15.01 0 0 1-11.558 14.604c-6.953 1.605-13.826-1.809-16.86-7.895z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" v-else-if="nome === 'check'">
    <path data-name="Path 183"
      d="M142.78 47.23l-76 75.93a14.41 14.41 0 0 1-20.38 0L7.22 84A14.41 14.41 0 0 1 27.6 63.58l29 29 65.8-65.74a14.41 14.41 0 0 1 20.38 20.39z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.26933 329" v-else-if="props.nome === 'x'">
    <path
      d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0">
    </path>
    <path
      d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" v-else-if="props.nome === 'arquivo'">
    <path
      d="M16.5211 0H3.53596C1.61815 0 0 1.61815 0 3.55594V14.2238C0 16.1615 1.61815 17.7797 3.55594 17.7797H16.4412C18.359 17.7797 19.9972 16.1615 19.9972 14.2238V3.55594C20.0771 1.61815 18.4589 0 16.5211 0ZM17.9395 14.3236C17.9395 15.1427 17.3402 15.8419 16.5211 15.8419H3.53596C2.7169 15.8419 2.0177 15.1227 2.0177 14.3236V3.55594C2.0177 2.73687 2.73687 2.03767 3.53596 2.03767H16.4212C17.2403 2.03767 17.9395 2.75685 17.9395 3.55594V14.3236Z" />
    <path
      d="M14.7032 3.45604C14.3037 3.45604 13.8841 3.65581 13.5845 3.95547C13.2848 4.25513 13.1849 4.57476 13.1849 4.97431C13.1849 5.79337 13.9041 6.49257 14.7032 6.49257C15.5023 6.49257 16.2215 5.77339 16.2215 4.97431C16.2215 4.17522 15.5023 3.45604 14.7032 3.45604Z" />
    <path
      d="M13.4846 7.61132C13.185 6.89214 12.1661 6.79226 11.6667 7.41155L10.3482 9.34933C9.94866 9.96863 9.02971 9.96863 8.63016 9.34933L8.23062 8.84991C7.83108 8.23061 6.91213 8.35048 6.51258 8.94979L4.25516 12.5057C3.85562 13.2249 4.25516 14.1239 5.17411 14.1239H14.7032C15.4224 14.1239 15.9218 13.3048 15.6222 12.6056L13.4846 7.61132Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-else-if="props.nome === 'estrela'">
    <g id="star">
      <path
        d="m29.911 13.75-6.229 6.072 1.471 8.576c.064.375-.09.754-.398.978-.174.127-.381.191-.588.191-.159 0-.319-.038-.465-.115l-7.702-4.049-7.701 4.048c-.336.178-.745.149-1.053-.076-.308-.224-.462-.603-.398-.978l1.471-8.576-6.23-6.071c-.272-.266-.371-.664-.253-1.025s.431-.626.808-.681l8.609-1.25 3.85-7.802c.337-.683 1.457-.683 1.794 0l3.85 7.802 8.609 1.25c.377.055.69.319.808.681s.019.758-.253 1.025z">
      </path>
    </g>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -63 512 511" v-else-if="props.nome === 'servidor'">
    <path
      d="m454.660156 270.101562c-3.316406 0-6.5625.296876-9.726562.839844.316406-3.316406.492187-6.675781.492187-10.074218 0-58.234376-47.207031-105.441407-105.441406-105.441407-55.714844 0-101.300781 43.21875-105.148437 97.953125-8.734376-4.011718-18.441407-6.261718-28.679688-6.261718-38.015625 0-68.832031 30.816406-68.832031 68.832031s30.816406 68.832031 68.832031 68.832031h248.503906c31.667969 0 57.339844-25.671875 57.339844-57.339844 0-31.667968-25.671875-57.339844-57.339844-57.339844zm0 0">
    </path>
    <path
      d="m38.722656 80.414062c29.472656 11.398438 68.976563 17.675782 111.230469 17.675782s81.757813-6.277344 111.230469-17.675782c23.523437-9.097656 38.722656-21.3125 38.722656-31.121093 0-9.804688-15.199219-22.019531-38.722656-31.117188-29.472656-11.398437-68.976563-17.675781-111.230469-17.675781s-81.757813 6.277344-111.230469 17.675781c-23.523437 9.097657-38.722656 21.3125-38.722656 31.117188 0 9.808593 15.199219 22.023437 38.722656 31.121093zm0 0">
    </path>
    <path
      d="m38.722656 195.726562c29.472656 11.398438 68.976563 17.675782 111.230469 17.675782 22.984375 0 45.148437-1.863282 65.332031-5.390625 7.304688-17.242188 18.179688-32.933594 32.167969-46.03125 15.140625-14.179688 33.066406-24.519531 52.453125-30.515625v-37.03125c-7.863281 5.128906-17.1875 9.816406-27.902344 13.960937-32.839844 12.699219-76.183594 19.695313-122.050781 19.695313-45.863281 0-89.210937-6.996094-122.050781-19.695313-10.714844-4.144531-20.039063-8.832031-27.902344-13.960937v70.171875c0 9.808593 15.199219 22.023437 38.722656 31.121093zm0 0">
    </path>
    <path
      d="m139.179688 243.339844c-41.84375-1.007813-81.019532-7.859375-111.277344-19.5625-10.714844-4.144532-20.039063-8.828125-27.902344-13.960938v70.175782c0 9.804687 15.199219 22.019531 38.722656 31.117187 19.40625 7.503906 43.160156 12.789063 69.179688 15.523437-.378906-3.511718-.578125-7.074218-.578125-10.6875 0-28.675781 12.28125-54.535156 31.855469-72.605468zm0 0">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else-if="props.nome === 'play'">
    <path
      d="M256,0C114.833,0,0,114.844,0,256s114.833,256,256,256s256-114.844,256-256S397.167,0,256,0z M357.771,264.969l-149.333,96c-1.75,1.135-3.771,1.698-5.771,1.698c-1.75,0-3.521-0.438-5.104-1.302C194.125,359.49,192,355.906,192,352V160c0-3.906,2.125-7.49,5.563-9.365c3.375-1.854,7.604-1.74,10.875,0.396l149.333,96c3.042,1.958,4.896,5.344,4.896,8.969S360.813,263.01,357.771,264.969z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-else-if="props.nome === 'whatsapp'">
    <path
      d="m17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z">
    </path>
    <path
      d="m20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-else-if="props.nome === 'atualizacoes'">
    <path
      d="M21,12a1.9748,1.9748,0,0,1,.72-1.52,1.9979,1.9979,0,0,0,.61-2.23,11.5756,11.5756,0,0,0-.8-1.75,11.3351,11.3351,0,0,0-1.12-1.58,2.0129,2.0129,0,0,0-2.24-.58,1.953,1.953,0,0,1-1.67-.13,1.99,1.99,0,0,1-.96-1.38,2.03,2.03,0,0,0-1.65-1.66,10.907,10.907,0,0,0-3.78,0A2.0069,2.0069,0,0,0,8.46,2.83,2.0208,2.0208,0,0,1,7.5,4.21a1.9964,1.9964,0,0,1-1.67.14,2.019,2.019,0,0,0-2.26.6,10.94,10.94,0,0,0-1.9,3.27,2.0436,2.0436,0,0,0,.61,2.26,1.9644,1.9644,0,0,1,0,3.04,1.9979,1.9979,0,0,0-.61,2.23,11.5756,11.5756,0,0,0,.8,1.75,11.3351,11.3351,0,0,0,1.12,1.58,2.0294,2.0294,0,0,0,2.24.58,1.953,1.953,0,0,1,1.67.13,1.99,1.99,0,0,1,.96,1.38,2.0069,2.0069,0,0,0,1.65,1.66,10.7487,10.7487,0,0,0,1.87.17,11.1174,11.1174,0,0,0,1.91-.17,2.0069,2.0069,0,0,0,1.65-1.66,2.0208,2.0208,0,0,1,.96-1.38,1.9933,1.9933,0,0,1,1.67-.14,1.9836,1.9836,0,0,0,2.26-.6,10.8184,10.8184,0,0,0,1.9-3.27,2.0436,2.0436,0,0,0-.61-2.26A1.9748,1.9748,0,0,1,21,12ZM8.0068,14.4072A.9992.9992,0,0,1,7.3,14.7a1.0286,1.0286,0,0,1-.2109-.0225.9993.9993,0,0,1-.7246-.624A5.7822,5.7822,0,0,1,6,12a6.0066,6.0066,0,0,1,6-6,5.7822,5.7822,0,0,1,2.0537.3643,1,1,0,0,1-.707,1.8711A3.7921,3.7921,0,0,0,12,8a4.006,4.006,0,0,0-3.9766,3.5615l.27-.2685a1,1,0,0,1,1.414,1.414ZM12,18a5.7822,5.7822,0,0,1-2.0537-.3643,1,1,0,0,1,.707-1.8711A3.7921,3.7921,0,0,0,12,16a4.006,4.006,0,0,0,3.9766-3.5615l-.27.2685a1,1,0,0,1-1.414-1.414l1.7-1.7a1,1,0,0,1,1.6425.3535A5.7822,5.7822,0,0,1,18,12,6.0066,6.0066,0,0,1,12,18Z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 19" v-else-if="props.nome === 'alinhar-esquerda'">
    <rect x="16" width="2" height="16" rx="1" transform="rotate(90 16 0)" />
    <rect x="25" y="5.5" width="2" height="25" rx="1" transform="rotate(90 25 5.5)" />
    <rect x="12" y="11" width="2" height="12" rx="1" transform="rotate(90 12 11)" />
    <rect x="21" y="17" width="2" height="21" rx="1" transform="rotate(90 21 17)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 19" v-else-if="props.nome === 'alinhar-centro'">
    <rect x="25" width="2" height="25" rx="1" transform="rotate(90 25 0)" />
    <rect x="21" y="5" width="2" height="17" rx="1" transform="rotate(90 21 5)" />
    <rect x="19" y="11" width="2" height="13" rx="1" transform="rotate(90 19 11)" />
    <rect x="23" y="17" width="2" height="21" rx="1" transform="rotate(90 23 17)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 19" v-else-if="props.nome === 'alinhar-direita'">
    <rect x="9" y="19" width="2" height="16" rx="1" transform="rotate(-90 9 19)" />
    <rect y="13.5" width="2" height="25" rx="1" transform="rotate(-90 0 13.5)" />
    <rect x="13" y="8" width="2" height="12" rx="1" transform="rotate(-90 13 8)" />
    <rect x="4" y="2" width="2" height="21" rx="1" transform="rotate(-90 4 2)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-else-if="props.nome === 'arredondamento'">
    <path
      d="M1.05556 10.5556C0.422222 10.5556 0 10.1333 0 9.5V5.27778C0 3.90556 0.527778 2.53333 1.58333 1.58333C2.53333 0.527778 3.90556 0 5.27778 0H9.5C10.1333 0 10.5556 0.422222 10.5556 1.05556C10.5556 1.68889 10.1333 2.11111 9.5 2.11111H5.27778C4.43333 2.11111 3.58889 2.42778 3.06111 3.06111C2.42778 3.58889 2.11111 4.43333 2.11111 5.27778V9.5C2.11111 10.1333 1.68889 10.5556 1.05556 10.5556Z" />
    <path
      d="M13.7223 2.11111C13.089 2.11111 12.6667 1.68889 12.6667 1.05556C12.6667 0.422222 13.089 0 13.7223 0C14.3556 0 14.7779 0.422222 14.7779 1.05556C14.7779 1.68889 14.3556 2.11111 13.7223 2.11111Z" />
    <path
      d="M17.9445 2.11111C17.3111 2.11111 16.8889 1.68889 16.8889 1.05556C16.8889 0.422222 17.3111 0 17.9445 0C18.5778 0 19 0.422222 19 1.05556C19 1.68889 18.5778 2.11111 17.9445 2.11111Z" />
    <path
      d="M17.9445 6.33334C17.3111 6.33334 16.8889 5.91112 16.8889 5.27778C16.8889 4.64445 17.3111 4.22223 17.9445 4.22223C18.5778 4.22223 19 4.64445 19 5.27778C19 5.91112 18.5778 6.33334 17.9445 6.33334Z" />
    <path
      d="M17.9445 10.5556C17.3111 10.5556 16.8889 10.1333 16.8889 9.5C16.8889 8.86666 17.3111 8.44444 17.9445 8.44444C18.5778 8.44444 19 8.86666 19 9.5C19 10.1333 18.5778 10.5556 17.9445 10.5556Z" />
    <path
      d="M1.05556 14.7778C0.422222 14.7778 0 14.3556 0 13.7222C0 13.0889 0.422222 12.6667 1.05556 12.6667C1.68889 12.6667 2.11111 13.0889 2.11111 13.7222C2.11111 14.3556 1.68889 14.7778 1.05556 14.7778Z" />
    <path
      d="M17.9445 14.7778C17.3111 14.7778 16.8889 14.3556 16.8889 13.7222C16.8889 13.0889 17.3111 12.6667 17.9445 12.6667C18.5778 12.6667 19 13.0889 19 13.7222C19 14.3556 18.5778 14.7778 17.9445 14.7778Z" />
    <path
      d="M1.05556 19C0.422222 19 0 18.5778 0 17.9444C0 17.3111 0.422222 16.8889 1.05556 16.8889C1.68889 16.8889 2.11111 17.3111 2.11111 17.9444C2.11111 18.5778 1.68889 19 1.05556 19Z" />
    <path
      d="M5.27772 19C4.64439 19 4.22217 18.5778 4.22217 17.9444C4.22217 17.3111 4.64439 16.8889 5.27772 16.8889C5.91106 16.8889 6.33328 17.3111 6.33328 17.9444C6.33328 18.5778 5.91106 19 5.27772 19Z" />
    <path
      d="M9.49989 19C8.86656 19 8.44434 18.5778 8.44434 17.9444C8.44434 17.3111 8.86656 16.8889 9.49989 16.8889C10.1332 16.8889 10.5554 17.3111 10.5554 17.9444C10.5554 18.5778 10.1332 19 9.49989 19Z" />
    <path
      d="M13.7223 19C13.089 19 12.6667 18.5778 12.6667 17.9444C12.6667 17.3111 13.089 16.8889 13.7223 16.8889C14.3556 16.8889 14.7779 17.3111 14.7779 17.9444C14.7779 18.5778 14.3556 19 13.7223 19Z" />
    <path
      d="M17.9445 19C17.3111 19 16.8889 18.5778 16.8889 17.9444C16.8889 17.3111 17.3111 16.8889 17.9445 16.8889C18.5778 16.8889 19 17.3111 19 17.9444C19 18.5778 18.5778 19 17.9445 19Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-else-if="props.nome === 'margem'">
    <path
      d="M4.22214 1.05556C4.22214 0.422222 4.64436 -7.38237e-08 5.2777 -4.61398e-08C5.91103 -1.84559e-08 6.33325 0.422222 6.33325 1.05556C6.33325 1.68889 5.91103 2.11111 5.2777 2.11111C4.64436 2.11111 4.22214 1.68889 4.22214 1.05556Z" />
    <path
      d="M-3.86084e-06 1.05555C-3.83315e-06 0.422221 0.422218 -7.68349e-07 1.05555 -7.40665e-07C1.14978 -7.36546e-07 17.3547 0.00934677 18 0.0273451C18 1.05556 17 0.516453 17 1.05556C17 1.59466 18 1 18 2.08377C17.3547 2.10176 1.14978 2.11111 1.05555 2.11111C0.422218 2.11111 -3.88852e-06 1.68889 -3.86084e-06 1.05555Z" />
    <path
      d="M2.11111 17.9445C2.11111 18.5778 1.68889 19 1.05556 19C0.422222 19 -1.84559e-08 18.5778 -4.61398e-08 17.9445C-7.38237e-08 17.3111 0.422222 16.8889 1.05556 16.8889C1.68889 16.8889 2.11111 17.3111 2.11111 17.9445Z" />
    <path
      d="M2.11111 13.7223C2.11111 14.3556 1.68889 14.7779 1.05556 14.7779C0.422222 14.7779 -1.84559e-08 14.3556 -4.61398e-08 13.7223C-7.38237e-08 13.089 0.422222 12.6668 1.05556 12.6668C1.68889 12.6668 2.11111 13.089 2.11111 13.7223Z" />
    <path
      d="M6.33328 13.7223C6.33328 14.3556 5.91106 14.7779 5.27772 14.7779C4.64439 14.7779 4.22217 14.3556 4.22217 13.7223C4.22217 13.089 4.64439 12.6668 5.27772 12.6668C5.91106 12.6668 6.33328 13.089 6.33328 13.7223Z" />
    <path
      d="M10.5554 13.7223C10.5554 14.3556 10.1332 14.7779 9.49989 14.7779C8.86656 14.7779 8.44434 14.3556 8.44434 13.7223C8.44434 13.089 8.86656 12.6668 9.49989 12.6668C10.1332 12.6668 10.5554 13.089 10.5554 13.7223Z" />
    <path
      d="M14.7779 13.7223C14.7779 14.3556 14.3556 14.7779 13.7223 14.7779C13.089 14.7779 12.6667 14.3556 12.6667 13.7223C12.6667 13.089 13.089 12.6668 13.7223 12.6668C14.3556 12.6668 14.7779 13.089 14.7779 13.7223Z" />
    <path
      d="M19 17.9445C19 18.5778 18.5778 19 17.9445 19C17.3111 19 16.8889 18.5778 16.8889 17.9445C16.8889 17.3111 17.3111 16.8889 17.9445 16.8889C18.5778 16.8889 19 17.3111 19 17.9445Z" />
    <path
      d="M19 13.7223C19 14.3556 18.5778 14.7779 17.9445 14.7779C17.3111 14.7779 16.8889 14.3556 16.8889 13.7223C16.8889 13.089 17.3111 12.6668 17.9445 12.6668C18.5778 12.6668 19 13.089 19 13.7223Z" />
    <path
      d="M19 1.05577C19 1.68911 18.5778 2.11133 17.9445 2.11133C17.3111 2.11133 16.8889 1.68911 16.8889 1.05577C16.8889 0.422439 17.3111 0.000217016 17.9445 0.000217099C18.5778 0.000217182 19 0.422439 19 1.05577Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-else-if="props.nome === 'preenchimento'">
    <path
      d="M16.8889 13.7222C16.8889 13.0889 17.3111 12.6667 17.9444 12.6667C18.5778 12.6667 19 13.0889 19 13.7222C19 14.3556 18.5778 14.7778 17.9444 14.7778C17.3111 14.7778 16.8889 14.3556 16.8889 13.7222Z" />
    <path
      d="M16.8889 17.9445C16.8889 17.3111 17.3111 16.8889 17.9444 16.8889C18.5778 16.8889 19 17.3111 19 17.9445C19 18.5778 18.5778 19 17.9444 19C17.3111 19 16.8889 18.5778 16.8889 17.9445Z" />
    <path
      d="M12.6667 17.9445C12.6667 17.3111 13.0889 16.8889 13.7223 16.8889C14.3556 16.8889 14.7778 17.3111 14.7778 17.9445C14.7778 18.5778 14.3556 19 13.7223 19C13.0889 19 12.6667 18.5778 12.6667 17.9445Z" />
    <path
      d="M8.44455 17.9445C8.44455 17.3111 8.86678 16.8889 9.50011 16.8889C10.1334 16.8889 10.5557 17.3111 10.5557 17.9445C10.5557 18.5778 10.1334 19 9.50011 19C8.86678 19 8.44455 18.5778 8.44455 17.9445Z" />
    <path
      d="M4.22214 1.05559C4.22214 0.422253 4.64436 3.04438e-05 5.2777 3.04714e-05C5.91103 3.04991e-05 6.33325 0.422253 6.33325 1.05559C6.33325 1.68892 5.91103 2.11114 5.2777 2.11114C4.64436 2.11114 4.22214 1.68892 4.22214 1.05559Z" />
    <path
      d="M4.22214 17.9445C4.22214 17.3111 4.64436 16.8889 5.2777 16.8889C5.91103 16.8889 6.33325 17.3111 6.33325 17.9445C6.33325 18.5778 5.91103 19 5.2777 19C4.64436 19 4.22214 18.5778 4.22214 17.9445Z" />
    <path
      d="M-3.86084e-06 1.05559C-3.83315e-06 0.422252 0.422218 2.97492e-05 1.05555 2.97769e-05C1.14978 2.9781e-05 17.3547 0.00937729 18 0.0273756C18 1.05559 17 0.516484 17 1.05559C17 1.59469 18 1.00003 18 2.0838C17.3547 2.10179 1.14978 2.11114 1.05555 2.11114C0.422218 2.11114 -3.88852e-06 1.68892 -3.86084e-06 1.05559Z" />
    <path
      d="M-2.72259e-05 5.27782C-2.71982e-05 4.64448 0.422195 4.22226 1.05553 4.22226C1.68886 4.22226 2.11108 4.64448 2.11108 5.27782C2.11108 5.91115 1.68886 6.33337 1.05553 6.33337C0.422195 6.33337 -2.72535e-05 5.91115 -2.72259e-05 5.27782Z" />
    <path
      d="M-2.72259e-05 9.50001C-2.71982e-05 8.86668 0.422195 8.44446 1.05553 8.44446C1.68886 8.44446 2.11108 8.86668 2.11108 9.50001C2.11108 10.1333 1.68886 10.5556 1.05553 10.5556C0.422195 10.5556 -2.72535e-05 10.1333 -2.72259e-05 9.50001Z" />
    <path
      d="M-2.72259e-05 13.7222C-2.71982e-05 13.0889 0.422195 12.6667 1.05553 12.6667C1.68886 12.6667 2.11108 13.0889 2.11108 13.7222C2.11108 14.3556 1.68886 14.7778 1.05553 14.7778C0.422195 14.7778 -2.72535e-05 14.3556 -2.72259e-05 13.7222Z" />
    <path
      d="M-2.72259e-05 17.9445C-2.71982e-05 17.3111 0.422195 16.8889 1.05553 16.8889C1.68886 16.8889 2.11108 17.3111 2.11108 17.9445C2.11108 18.5778 1.68886 19 1.05553 19C0.422195 19 -2.72535e-05 18.5778 -2.72259e-05 17.9445Z" />
    <path
      d="M19 9.50014C19 10.1335 18.5778 10.5557 17.9445 10.5557C17.3111 10.5557 16.8889 10.1335 16.8889 9.50014C16.8889 8.86681 17.3111 8.44458 17.9445 8.44458C18.5778 8.44458 19 8.86681 19 9.50014Z" />
    <path
      d="M19 5.27797C19 5.9113 18.5778 6.33353 17.9445 6.33353C17.3111 6.33353 16.8889 5.9113 16.8889 5.27797C16.8889 4.64464 17.3111 4.22242 17.9445 4.22242C18.5778 4.22242 19 4.64464 19 5.27797Z" />
    <path
      d="M19 1.0558C19 1.68914 18.5778 2.11136 17.9445 2.11136C17.3111 2.11136 16.8889 1.68914 16.8889 1.0558C16.8889 0.42247 17.3111 0.000247534 17.9445 0.000247617C18.5778 0.0002477 19 0.42247 19 1.0558Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" v-else-if="props.nome === 'oculto'">
    <path
      d="m79.891 65.078 7.27-7.27c.529 1.979.839 4.048.839 6.192 0 13.234-10.766 24-24 24-2.144 0-4.213-.31-6.192-.839l7.27-7.27c7.949-.542 14.271-6.864 14.813-14.813zm47.605-3.021c-.492-.885-7.47-13.112-21.11-23.474l-5.821 5.821c9.946 7.313 16.248 15.842 18.729 19.602-4.741 7.219-23.339 31.994-55.294 31.994-4.792 0-9.248-.613-13.441-1.591l-6.573 6.573c6.043 1.853 12.685 3.018 20.014 3.018 41.873 0 62.633-36.504 63.496-38.057.672-1.209.672-2.677 0-3.886zm-16.668-39.229-88 88c-.781.781-1.805 1.172-2.828 1.172s-2.047-.391-2.828-1.172c-1.563-1.563-1.563-4.094 0-5.656l11.196-11.196c-18.1-10.927-27.297-27.012-27.864-28.033-.672-1.209-.672-2.678 0-3.887.863-1.552 21.623-38.056 63.496-38.056 10.827 0 20.205 2.47 28.222 6.122l12.95-12.95c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656zm-76.495 65.183 10.127-10.127c-2.797-3.924-4.46-8.709-4.46-13.884 0-13.234 10.766-24 24-24 5.175 0 9.96 1.663 13.884 4.459l8.189-8.189c-6.47-2.591-13.822-4.27-22.073-4.27-31.955 0-50.553 24.775-55.293 31.994 3.01 4.562 11.662 16.11 25.626 24.017zm15.934-15.935 21.809-21.809c-2.379-1.405-5.118-2.267-8.076-2.267-8.822 0-16 7.178-16 16 0 2.958.862 5.697 2.267 8.076z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999" v-else-if="props.nome === 'visivel'">
    <path
      d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418C447.361,287.923,358.746,385.406,255.997,385.406z">
    </path>
    <path
      d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 22" v-else-if="props.nome === 'envelope'">
    <path
      d="M15.7949 14.7724C15.5128 14.9487 15.1955 15.0192 14.9135 15.0192C14.6314 15.0192 14.3141 14.9487 14.0321 14.7724L0 6.20512V17.5929C0 20.0256 1.97436 22 4.40705 22H25.4551C27.8878 22 29.8622 20.0256 29.8622 17.5929V6.20512L15.7949 14.7724Z" />
    <path
      d="M25.4551 0H4.40702C2.32689 0 0.564068 1.48077 0.140991 3.45513L14.9487 12.4808L29.7211 3.45513C29.298 1.48077 27.5352 0 25.4551 0Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 23" v-else-if="props.nome === 'moverCima'">
    <path opacity="0.3"
      d="M5.34553 16.1264C7.05075 16.1264 8.43301 17.5087 8.43301 19.2139C8.43301 20.9191 7.05075 22.3013 5.34553 22.3013C3.64032 22.3013 2.25806 20.9191 2.25806 19.2139C2.25806 17.5087 3.64032 16.1264 5.34553 16.1264Z" />
    <path
      d="M4.61798 0.99065C5.01997 0.58866 5.67122 0.58866 6.07331 0.99065L10.1899 5.10729C10.3909 5.30828 10.4915 5.57164 10.4915 5.8349C10.4915 6.09816 10.3909 6.36152 10.1899 6.56252C9.78796 6.96451 9.1367 6.96451 8.73461 6.56252L6.37475 4.20265L6.37475 12.0098C6.37475 12.5781 5.91441 13.0389 5.34559 13.0389C4.77677 13.0389 4.31643 12.5781 4.31643 12.0098L4.31643 4.20265L1.95657 6.56252C1.55458 6.96451 0.90333 6.96451 0.501238 6.56252C0.0991454 6.16053 0.0992484 5.50928 0.501238 5.10718L4.61798 0.99065Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-else-if="props.nome === 'duplicar'">
    <path
      d="m15 23h-10a4 4 0 0 1 -4-4v-10a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4zm-10-16a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-10a2 2 0 0 0 -2-2z">
    </path>
    <path
      d="m19 19v-2a2 2 0 0 0 2-2v-10a2 2 0 0 0 -2-2h-10a2 2 0 0 0 -2 2h-2a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.442 27.442" v-else-if="props.nome === 'mobile'">
    <path
      d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469zM19.995,21.1H7.448V3.373h12.547V21.1z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 548.172 548.172" v-else-if="props.nome === 'desktop'">
    <path
      d="M534.75,49.965c-8.945-8.945-19.694-13.422-32.261-13.422H45.681c-12.562,0-23.313,4.477-32.264,13.422C4.471,58.913,0,69.663,0,82.226v310.633c0,12.566,4.471,23.315,13.417,32.265c8.951,8.945,19.702,13.414,32.264,13.414h155.318c0,7.231-1.524,14.661-4.57,22.269c-3.044,7.614-6.09,14.273-9.136,19.981c-3.042,5.715-4.565,9.897-4.565,12.56c0,4.948,1.807,9.24,5.424,12.847c3.615,3.621,7.898,5.435,12.847,5.435h146.179c4.949,0,9.233-1.813,12.848-5.435c3.62-3.606,5.427-7.898,5.427-12.847c0-2.468-1.526-6.611-4.571-12.415c-3.046-5.801-6.092-12.566-9.134-20.267c-3.046-7.71-4.569-15.085-4.569-22.128h155.318c12.56,0,23.309-4.469,32.254-13.414c8.949-8.949,13.422-19.698,13.422-32.265V82.226C548.176,69.663,543.699,58.913,534.75,49.965z M511.627,319.768c0,2.475-0.903,4.613-2.711,6.424c-1.81,1.804-3.952,2.707-6.427,2.707H45.681c-2.473,0-4.615-0.903-6.423-2.707c-1.807-1.817-2.712-3.949-2.712-6.424V82.226c0-2.475,0.902-4.615,2.712-6.423c1.809-1.805,3.951-2.712,6.423-2.712h456.815c2.471,0,4.617,0.904,6.42,2.712c1.808,1.809,2.711,3.949,2.711,6.423V319.768L511.627,319.768z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337 216" v-else-if="props.nome === 'alinhar-center'">
    <rect x="147" width="43" height="216" rx="14" />
    <path
      d="M97.2071 99.0025C103.436 103.384 103.436 112.616 97.207 116.998L44.8281 153.837C37.5398 158.963 27.5 153.749 27.5 144.839L27.5 71.161C27.5 62.2506 37.5398 57.0375 44.8281 62.1635L97.2071 99.0025Z" />
    <path
      d="M239.793 116.998C233.564 112.616 233.564 103.384 239.793 99.0025L292.172 62.1635C299.46 57.0375 309.5 62.2506 309.5 71.161L309.5 144.839C309.5 153.749 299.46 158.963 292.172 153.837L239.793 116.998Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 216" v-else-if="props.nome === 'alinhar-flex-end'">
    <rect x="147" width="75" height="216" rx="14" />
    <path
      d="M97.2071 99.0025C103.436 103.384 103.436 112.616 97.207 116.998L44.8281 153.837C37.5398 158.963 27.5 153.749 27.5 144.839L27.5 71.161C27.5 62.2506 37.5398 57.0375 44.8281 62.1635L97.2071 99.0025Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206 216" v-else-if="props.nome === 'alinhar-flex-start'">
    <rect width="75" height="216" rx="14" />
    <path
      d="M108.793 116.997C102.564 112.616 102.564 103.384 108.793 99.0025L161.172 62.1635C168.46 57.0375 178.5 62.2506 178.5 71.161L178.5 144.839C178.5 153.749 168.46 158.963 161.172 153.837L108.793 116.997Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 134" v-else-if="props.nome === 'alinhar-space-between'">
    <path
      d="M12.7929 75.9975C6.56375 71.6164 6.56375 62.3836 12.7929 58.0025L65.1719 21.1635C72.4602 16.0375 82.5 21.2506 82.5 30.161L82.5 103.839C82.5 112.749 72.4602 117.963 65.1719 112.837L12.7929 75.9975Z" />
    <path
      d="M218.207 58.0025C224.436 62.3836 224.436 71.6164 218.207 75.9975L165.828 112.837C158.54 117.963 148.5 112.749 148.5 103.839L148.5 30.161C148.5 21.2506 158.54 16.0375 165.828 21.1635L218.207 58.0025Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374 254" v-else-if="props.nome === 'align-center'">
    <rect x="272" y="254" width="170" height="60" rx="10" transform="rotate(-180 272 254)" />
    <rect x="226" y="157" width="78" height="60" rx="10" transform="rotate(-180 226 157)" />
    <rect x="247" y="60" width="119" height="60" rx="10" transform="rotate(-180 247 60)" />
    <rect x="332.811" y="168.101" width="14" height="72" transform="rotate(135 332.811 168.101)" />
    <rect x="322.912" y="76.1766" width="14" height="72" transform="rotate(45 322.912 76.1766)" />
    <rect x="41.0122" y="86.0762" width="14" height="72" transform="rotate(-45 41.0122 86.0762)" />
    <rect x="50.9116" y="178" width="14" height="72" transform="rotate(-135 50.9116 178)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269 254" v-else-if="props.nome === 'align-flex-end'">
    <rect x="170" y="254" width="170" height="60" rx="10" transform="rotate(-180 170 254)" />
    <rect x="170" y="157" width="78" height="60" rx="10" transform="rotate(-180 170 157)" />
    <rect x="170" y="60" width="119" height="60" rx="10" transform="rotate(-180 170 60)" />
    <rect x="208.189" y="86.0762" width="14" height="72" transform="rotate(-45 208.189 86.0762)" />
    <rect x="218.088" y="178" width="14" height="72" transform="rotate(-135 218.088 178)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269 254" v-else-if="props.nome === 'align-flex-start'">
    <rect x="99" width="170" height="60" rx="10" />
    <rect x="99" y="97" width="78" height="60" rx="10" />
    <rect x="99" y="194" width="119" height="60" rx="10" />
    <rect x="60.8113" y="167.924" width="14" height="72" transform="rotate(135 60.8113 167.924)" />
    <rect x="50.9119" y="76" width="14" height="72" transform="rotate(45 50.9119 76)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 322 254" v-else-if="props.nome === 'align-stretch'">
    <path
      d="M86.0001 254L236 254C241.523 254 246 249.523 246 244V204C246 198.477 241.523 194 236 194L86.0001 194C80.4773 194 76.0001 198.477 76.0001 204V244C76.0001 249.523 80.4773 254 86.0001 254Z" />
    <path
      d="M86.0001 157L236 157C241.523 157 246 152.523 246 147V107C246 101.477 241.523 97 236 97L86.0001 97C80.4773 97 76.0001 101.477 76.0001 107V147C76.0001 152.523 80.4773 157 86.0001 157Z" />
    <path
      d="M86.0001 60L236 60C241.523 60 246 55.5228 246 50V10C246 4.47715 241.523 0 236 0L86.0001 0C80.4773 0 76.0001 4.47715 76.0001 10V50C76.0001 55.5228 80.4773 60 86.0001 60Z" />
    <path d="M270.9 76.1764L261.001 86.0759L311.912 136.988L321.812 127.088L270.9 76.1764Z" />
    <path d="M261 168.101L270.9 178L321.811 127.089L311.912 117.189L261 168.101Z" />
    <path d="M50.9117 178L60.8112 168.101L9.89947 117.189L-2.14577e-05 127.088L50.9117 178Z" />
    <path d="M60.8112 86.0763L50.9117 76.1768L5.14984e-05 127.088L9.89955 136.988L60.8112 86.0763Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 270" v-else-if="props.nome === 'direction-column-reverse'">
    <rect x="260" width="125" height="260" rx="15" transform="rotate(90 260 0)" fill-opacity="0.5" />
    <rect x="260" y="145" width="125" height="260" rx="15" transform="rotate(90 260 145)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 270" v-else-if="props.nome === 'direction-column'">
    <rect y="270" width="125" height="260" rx="15" transform="rotate(-90 0 270)" fill-opacity="0.5" />
    <rect y="125" width="125" height="260" rx="15" transform="rotate(-90 0 125)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 260" v-else-if="props.nome === 'direction-row-reverse'">
    <rect width="125" height="260" rx="15" fill-opacity="0.5" />
    <rect x="145" width="125" height="260" rx="15" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 260" v-else-if="props.nome === 'direction-row'">
    <rect x="270" y="260" width="125" height="260" rx="15" transform="rotate(-180 270 260)" fill-opacity="0.5" />
    <rect x="125" y="260" width="125" height="260" rx="15" transform="rotate(-180 125 260)" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" v-else-if="props.nome === 'target-self'">
    <path
      d="M385.93,481.13H114.07c-21.79,0-42.27-8.48-57.67-23.89-15.4-15.4-23.89-35.89-23.89-57.67V127.7c0-21.79,8.48-42.27,23.89-57.67,15.4-15.4,35.88-23.89,57.67-23.89h108.75c15.02,0,27.19,12.17,27.19,27.19s-12.17,27.19-27.19,27.19h-108.75c-7.26,0-14.09,2.83-19.22,7.96-5.13,5.13-7.96,11.96-7.96,19.22v271.86c0,7.26,2.83,14.09,7.96,19.22,5.13,5.13,11.96,7.96,19.22,7.96h271.86c7.26,0,14.09-2.83,19.22-7.96,5.13-5.13,7.96-11.96,7.96-19.22v-108.74c0-15.02,12.17-27.19,27.19-27.19s27.19,12.17,27.19,27.19v108.75c0,21.79-8.48,42.27-23.89,57.67-15.4,15.4-35.89,23.89-57.67,23.89h0Z" />
    <path
      d="M440.3,46.14c6.96,0,13.92,2.65,19.22,7.96,10.62,10.62,10.62,27.83,0,38.45l-225.45,225.45h97.48c15.02,0,27.19,12.17,27.19,27.19s-12.17,27.19-27.19,27.19h-163.12c-3.76,0-7.34-.76-10.6-2.14-3.04-1.29-5.89-3.15-8.39-5.59t0,0s-.04-.04-.05-.05c0,0-.01-.01-.02-.02-.01-.01-.03-.03-.04-.04,0,0-.02-.02-.03-.03-.01-.01-.02-.02-.03-.03-.02-.02-.04-.04-.06-.06-.02-.02-.04-.04-.06-.06-.01,0-.02-.02-.03-.03-.01,0-.02-.02-.03-.03-.02-.01-.03-.03-.04-.04,0,0-.01-.01-.02-.02-.02-.02-.04-.04-.05-.05,0,0,0,0,0,0-2.44-2.5-4.3-5.35-5.59-8.39-1.38-3.26-2.14-6.84-2.14-10.6v-163.12c0-15.02,12.17-27.19,27.19-27.19s27.19,12.17,27.19,27.19v97.48L421.08,54.1c5.3-5.31,12.26-7.96,19.22-7.96h0Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" v-else-if="props.nome === 'target-blank'">
    <path
      d="M358.75,494.68H86.88c-21.79,0-42.27-8.48-57.67-23.89-15.4-15.4-23.89-35.89-23.89-57.67V141.25c0-21.79,8.48-42.27,23.89-57.67,15.4-15.4,35.88-23.89,57.67-23.89h108.75c15.02,0,27.19,12.17,27.19,27.19s-12.17,27.19-27.19,27.19h-108.75c-7.26,0-14.09,2.83-19.22,7.96-5.13,5.13-7.96,11.96-7.96,19.22v271.86c0,7.26,2.83,14.09,7.96,19.22,5.13,5.13,11.96,7.96,19.22,7.96h271.86c7.26,0,14.09-2.83,19.22-7.96,5.13-5.13,7.96-11.96,7.96-19.22v-108.74c0-15.02,12.17-27.19,27.19-27.19s27.19,12.17,27.19,27.19v108.75c0,21.79-8.48,42.27-23.89,57.67-15.4,15.4-35.89,23.89-57.67,23.89h0ZM195.63,331.56c-6.96,0-13.92-2.65-19.22-7.96-10.62-10.62-10.62-27.83,0-38.45L401.86,59.7h-97.48c-15.02,0-27.19-12.17-27.19-27.19s12.17-27.19,27.19-27.19h163.12c3.76,0,7.34.76,10.6,2.14,3.04,1.29,5.89,3.15,8.39,5.59t0,0s.04.04.05.05c0,0,.01.01.02.02.01.01.03.03.04.04,0,0,.02.02.03.03.01.01.02.02.03.03.02.02.04.04.06.06.02.02.04.04.06.06.01,0,.02.02.03.03.01,0,.02.02.03.03.02.01.03.03.04.04,0,0,.01.01.02.02.02.02.04.04.05.05,0,0,0,0,0,0,2.44,2.5,4.3,5.35,5.59,8.39,1.38,3.26,2.14,6.84,2.14,10.6v163.12c0,15.02-12.17,27.19-27.19,27.19s-27.19-12.17-27.19-27.19v-97.48l-225.46,225.45c-5.3,5.31-12.26,7.96-19.22,7.96h0Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524.235 524.235" v-else-if="props.nome === 'info'">
    <path
      d="m262.118 0c-144.53 0-262.118 117.588-262.118 262.118s117.588 262.118 262.118 262.118 262.118-117.588 262.118-262.118-117.589-262.118-262.118-262.118zm17.05 417.639c-12.453 2.076-37.232 7.261-49.815 8.303-10.651.882-20.702-5.215-26.829-13.967-6.143-8.751-7.615-19.95-3.968-29.997l49.547-136.242h-51.515c-.044-28.389 21.25-49.263 48.485-57.274 12.997-3.824 37.212-9.057 49.809-8.255 7.547.48 20.702 5.215 26.829 13.967 6.143 8.751 7.615 19.95 3.968 29.997l-49.547 136.242h51.499c.01 28.356-20.49 52.564-48.463 57.226zm15.714-253.815c-18.096 0-32.765-14.671-32.765-32.765 0-18.096 14.669-32.765 32.765-32.765s32.765 14.669 32.765 32.765c0 18.095-14.668 32.765-32.765 32.765z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-else-if="props.nome === 'centralizar'">
    <path
      d="m11 6.724v4.276h-4.276c-.267 0-.401-.323-.212-.512l1.281-1.281-2.384-2.384c-1.662.652-3.409-.583-3.409-2.323 0-1.381 1.119-2.5 2.5-2.5 1.742 0 2.974 1.749 2.323 3.409l2.384 2.384 1.281-1.281c.189-.189.512-.055.512.212z">
    </path>
    <path
      d="m21.512 6.512 1.281 1.281 2.384-2.384c-.651-1.66.581-3.409 2.323-3.409 1.381 0 2.5 1.119 2.5 2.5 0 1.74-1.747 2.975-3.409 2.323l-2.384 2.384 1.281 1.281c.189.189.055.512-.212.512h-4.276v-4.276c0-.267.323-.401.512-.212z">
    </path>
    <path
      d="m10.488 25.488-1.281-1.281-2.384 2.384c.651 1.66-.581 3.409-2.323 3.409-1.381 0-2.5-1.119-2.5-2.5 0-1.74 1.747-2.975 3.409-2.323l2.384-2.384-1.281-1.281c-.189-.189-.055-.512.212-.512h4.276v4.276c0 .267-.323.401-.512.212z">
    </path>
    <path
      d="m21 25.276v-4.276h4.276c.267 0 .401.323.212.512l-1.281 1.281 2.384 2.384c1.662-.652 3.409.583 3.409 2.323 0 1.381-1.119 2.5-2.5 2.5-1.742 0-2.974-1.749-2.323-3.409l-2.384-2.384-1.281 1.281c-.189.189-.512.055-.512-.212z">
    </path>
    <path d="m21.5 16c0 3.038-2.462 5.5-5.5 5.5s-5.5-2.462-5.5-5.5 2.462-5.5 5.5-5.5 5.5 2.462 5.5 5.5z"></path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" v-else-if="props.nome === 'pausar'">
    <path
      d="m13 5v22a3 3 0 0 1 -3 3h-1a3 3 0 0 1 -3-3v-22a3 3 0 0 1 3-3h1a3 3 0 0 1 3 3zm10-3h-1a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h1a3 3 0 0 0 3-3v-22a3 3 0 0 0 -3-3z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else-if="props.nome === 'playsolto'">
    <path
      d="m468.8 235.007-401.359-231.73a24.2 24.2 0 0 0 -12.087-3.285h-.07a24.247 24.247 0 0 0 -12.094 3.287 24 24 0 0 0 -12.11 20.992v463.456a24.186 24.186 0 0 0 36.36 20.994l401.36-231.731a24.238 24.238 0 0 0 0-41.983z"
      fill-rule="evenodd"></path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" v-else-if="props.nome === 'googletag-colorido'">
    <polygon fill="#8AB4F8" points="150.26 245.52 105.83 202.19 201.26 104.73 247.27 149.82" />
    <path fill="#4285F4"
      d="M150.45,53.94 L106.17,8.73 L9.36,104.63 C-3.12,117.11 -3.12,137.34 9.36,149.84 L104.72,245.82 L149.81,203.64 L77.16,127.23 L150.45,53.94 Z" />
    <path fill="#8AB4F8"
      d="M246.63,105.37 L150.63,9.37 C138.13,-3.12 117.87,-3.12 105.37,9.37 C92.88,21.87 92.88,42.13 105.37,54.62 L201.37,150.62 C213.87,163.12 234.13,163.12 246.63,150.62 C259.12,138.13 259.12,117.87 246.63,105.37 Z" />
    <circle fill="#246FDB" cx="127.27" cy="224.73" r="31.27" />
  </svg>

  <svg width="527" height="527" viewBox="0 0 527 527" fill="none" xmlns="http://www.w3.org/2000/svg"
    v-else-if="props.nome === 'unic-ai'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M255 8.5C255 3.80558 258.806 0 263.5 0C268.194 0 272 3.80558 272 8.5V102.5C272 109.956 278.044 116 285.5 116V116C292.956 116 299 109.956 299 102.5V54.5C299 49.8056 302.806 46 307.5 46C312.194 46 316 49.8056 316 54.5V71C316 95.8528 336.147 116 361 116V116C389.167 116 412 138.833 412 167V167C412 190.196 430.804 209 454 209H470.5C475.194 209 479 212.806 479 217.5C479 222.194 475.194 226 470.5 226H426.5C418.492 226 412 232.492 412 240.5V240.5C412 248.508 418.492 255 426.5 255H518.5C523.194 255 527 258.806 527 263.5C527 268.194 523.194 272 518.5 272H424.5C417.596 272 412 277.596 412 284.5V284.5C412 291.404 417.596 297 424.5 297H470.5C475.194 297 479 300.806 479 305.5C479 310.194 475.194 314 470.5 314H459C433.043 314 412 335.043 412 361V361C412 389.167 389.167 412 361 412V412C336.147 412 316 432.147 316 457V468.5C316 473.194 312.194 477 307.5 477C302.806 477 299 473.194 299 468.5V425.5C299 418.044 292.956 412 285.5 412V412C278.044 412 272 418.044 272 425.5V518.5C272 523.194 268.194 527 263.5 527C258.806 527 255 523.194 255 518.5V425.5C255 418.044 248.956 412 241.5 412V412C234.044 412 228 418.044 228 425.5V468.5C228 473.194 224.194 477 219.5 477C214.806 477 211 473.194 211 468.5V456C211 431.699 191.301 412 167 412V412C138.833 412 116 389.167 116 361V361C116 335.043 94.9574 314 69 314H56.5C51.8056 314 48 310.194 48 305.5C48 300.806 51.8056 297 56.5 297H103.5C110.404 297 116 291.404 116 284.5V284.5C116 277.596 110.404 272 103.5 272H8.5C3.80558 272 0 268.194 0 263.5C0 258.806 3.80558 255 8.5 255H101.5C109.508 255 116 248.508 116 240.5V240.5C116 232.492 109.508 226 101.5 226H56.5C51.8056 226 48 222.194 48 217.5C48 212.806 51.8056 209 56.5 209H74C97.196 209 116 190.196 116 167V167C116 138.833 138.833 116 167 116V116C191.301 116 211 96.3005 211 72V54.5C211 49.8056 214.806 46 219.5 46C224.194 46 228 49.8056 228 54.5V102.5C228 109.956 234.044 116 241.5 116V116C248.956 116 255 109.956 255 102.5V8.5Z"
      fill="url(#paint0_linear_17_19)" />
    <path
      d="M296.702 194H208V279.46C208 290.362 210.25 299.804 214.757 307.787C219.258 315.769 225.686 321.967 234.043 326.379C242.394 330.791 252.253 333 263.609 333C274.964 333 285.047 330.791 293.468 326.379C301.888 321.967 308.409 315.769 313.047 307.787C317.681 299.804 320 290.362 320 279.46V194H296.714H296.702ZM310.273 258.71H256.403V205.147H310.273V258.71Z"
      fill="white" />
    <defs>
      <linearGradient id="paint0_linear_17_19" x1="5.6275" y1="467.346" x2="539.788" y2="455.955"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F53B3B" />
        <stop offset="0.2" stop-color="#F8428F" />
        <stop offset="0.4" stop-color="#EA42F8" />
        <stop offset="0.6" stop-color="#5C42F8" />
        <stop offset="0.8" stop-color="#42C2F8" />
        <stop offset="1" stop-color="#42F8B7" />
      </linearGradient>
    </defs>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'seta'">
    <path
      d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 391" v-else-if="props.nome === 'active-campaign-icone'">
    <path
      d="M1.27262883,-2.84217094e-14 L4.32189792,2.14204853 C13.8099129,8.74459812 236.507358,163.967515 242.719299,168.61702 C251.514298,174.79116 256,182.426933 256,191.284934 L256,195.682433 C255.899198,202.524388 253.442142,212.151007 242.719299,220.202589 L242.681498,220.22779 C237.515381,223.919673 184.93439,260.347098 129.266329,298.929173 C124.36507,302.32558 119.40788,305.76077 114.439063,309.20412 L111.125282,311.500559 C61.9597303,345.572363 12.6198159,379.77651 5.94733474,384.497711 L5.94733474,390.63405 L2.30585224,384.510312 C2.12944825,384.195304 1.96564453,383.930698 2.15464882,383.502289 L2.04124625,383.502289 L2.04124625,351.472363 C2.04124625,340.547916 3.25087365,335.293596 15.4101491,327.191613 C20.5896225,323.86287 64.9397948,293.237209 109.989121,262.064365 L115.289947,258.39606 C156.802003,229.666506 197.626531,201.363146 207.652705,194.422405 C186.484225,179.717872 27.7962298,69.5535758 16.9221834,61.5901954 L15.6369543,60.6577743 C7.59797214,54.8364424 1.27262883,50.2499385 1.27262883,38.1284638 L1.27262883,-2.84217094e-14 Z M21.8614953,111.361323 C32.5969385,118.266279 136.423291,190.314712 137.469115,191.058129 L139.825368,192.683565 L137.431314,194.283802 C137.431314,194.283802 130.412955,198.958508 122.625978,204.401831 C115.960427,209.290742 109.723286,211.735197 103.523945,211.735197 C98.0176207,211.735197 92.5616971,209.819954 86.8159669,206.002067 C80.6952523,201.916047 63.4532434,190.021458 46.000469,177.937961 L43.6202635,176.289756 C22.2051,161.458254 1.21945563,146.863887 0.831618841,146.591721 L-2.84217094e-14,146.012108 L-2.84217094e-14,121.542354 C-2.84217094e-14,115.922626 2.41925481,111.474726 6.66555102,109.320077 C11.2142541,107.001624 16.8969828,107.757641 21.8614953,111.361323 Z"
      fill="#004CFF"></path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.283 515.283" v-else-if="props.nome === 'download'">
    <path
      d="m400.775 515.283h-286.268c-30.584 0-59.339-11.911-80.968-33.54-21.628-21.626-33.539-50.382-33.539-80.968v-28.628c0-15.811 12.816-28.628 28.627-28.628s28.627 12.817 28.627 28.628v28.628c0 15.293 5.956 29.67 16.768 40.483 10.815 10.814 25.192 16.771 40.485 16.771h286.268c15.292 0 29.669-5.957 40.483-16.771 10.814-10.815 16.771-25.192 16.771-40.483v-28.628c0-15.811 12.816-28.628 28.626-28.628s28.628 12.817 28.628 28.628v28.628c0 30.584-11.911 59.338-33.54 80.968-21.629 21.629-50.384 33.54-80.968 33.54zm-143.134-114.509c-3.96 0-7.73-.804-11.16-2.257-3.2-1.352-6.207-3.316-8.838-5.885-.001-.001-.001-.002-.002-.002-.019-.018-.038-.037-.057-.056-.005-.004-.011-.011-.016-.016-.016-.014-.03-.029-.045-.044-.01-.01-.019-.018-.029-.029-.01-.01-.023-.023-.032-.031-.02-.02-.042-.042-.062-.062l-114.508-114.509c-11.179-11.179-11.179-29.305 0-40.485 11.179-11.179 29.306-11.18 40.485 0l65.638 65.638v-274.409c-.001-15.811 12.815-28.627 28.626-28.627s28.628 12.816 28.628 28.627v274.408l65.637-65.637c11.178-11.179 29.307-11.179 40.485 0 11.179 11.179 11.179 29.306 0 40.485l-114.508 114.507c-.02.02-.042.042-.062.062-.011.01-.023.023-.032.031-.01.011-.019.019-.029.029-.014.016-.03.03-.044.044-.005.005-.012.012-.017.016-.018.019-.037.038-.056.056-.001 0-.001.001-.002.002-.315.307-.634.605-.96.895-2.397 2.138-5.067 3.805-7.89 4.995-.01.004-.018.008-.028.012-.011.004-.02.01-.031.013-3.412 1.437-7.158 2.229-11.091 2.229z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3" v-else-if="props.nome === 'google-maps-icone'">
    <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z" />
    <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z" />
    <path fill="#4285f4"
      d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3" />
    <path fill="#fbbc04"
      d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3" />
    <path fill="#34a853"
      d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2" />
  </svg>

  <svg viewBox="0 -28.5 256 256" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'discord-icone'">
    <path
      d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
      fill="#5865F2" fill-rule="nonzero">

    </path>
  </svg>

  <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'position-normal'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10V48C0 53.5228 4.47715 58 10 58H48C53.5228 58 58 53.5229 58 48V10C58 4.47715 53.5229 0 48 0H10ZM9 3C5.68629 3 3 5.68629 3 9V44C3 47.3137 5.68629 50 9 50H44C47.3137 50 50 47.3137 50 44V9C50 5.68629 47.3137 3 44 3H9Z"
      fill="white" />
    <rect x="9" y="9" width="34" height="34" rx="5" fill="white" />
  </svg>

  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'position-fixed'">
    <rect width="34" height="34" rx="5" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M6 40V54C6 59.5228 10.4772 64 16 64H54C59.5228 64 64 59.5229 64 54V16C64 10.4772 59.5229 6 54 6H40V34C40 37.3137 37.3137 40 34 40H6Z"
      fill="white" />
  </svg>

  <svg viewBox="0 0 45 26" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'decoration-linethrough'">
    <path d="M0 14.7H44.5005V16.5H0V14.7Z" />
    <path
      d="M38.2365 25.5001L38.0925 21.8641V16.3921C38.0925 15.3121 37.9725 14.3881 37.7325 13.6201C37.5165 12.8521 37.1565 12.2641 36.6525 11.8561C36.1725 11.4241 35.5125 11.2081 34.6725 11.2081C33.9045 11.2081 33.2085 11.3761 32.5845 11.7121C31.9605 12.0481 31.4325 12.5641 31.0005 13.2601L26.8245 11.8201C27.1605 11.0521 27.6525 10.3081 28.3005 9.58812C28.9485 8.84412 29.7885 8.24412 30.8205 7.78812C31.8765 7.30812 33.1605 7.06812 34.6725 7.06812C36.4965 7.06812 38.0085 7.41612 39.2085 8.11212C40.4325 8.80812 41.3325 9.79212 41.9085 11.0641C42.5085 12.3121 42.7965 13.8001 42.7725 15.5281L42.6645 25.5001H38.2365ZM33.1965 25.9321C31.0365 25.9321 29.3565 25.4521 28.1565 24.4921C26.9805 23.5321 26.3925 22.1761 26.3925 20.4241C26.3925 18.5041 27.0285 17.0641 28.3005 16.1041C29.5965 15.1201 31.4085 14.6281 33.7365 14.6281H38.3085V18.1561H35.3925C33.8325 18.1561 32.7525 18.3481 32.1525 18.7321C31.5525 19.0921 31.2525 19.6081 31.2525 20.2801C31.2525 20.8081 31.4925 21.2281 31.9725 21.5401C32.4525 21.8281 33.1245 21.9721 33.9885 21.9721C34.7805 21.9721 35.4765 21.7921 36.0765 21.4321C36.7005 21.0481 37.1925 20.5681 37.5525 19.9921C37.9125 19.3921 38.0925 18.7801 38.0925 18.1561H39.2445C39.2445 20.6041 38.7765 22.5121 37.8405 23.8801C36.9285 25.2481 35.3805 25.9321 33.1965 25.9321Z" />
    <path
      d="M20.196 25.5L10.548 0.300049H15.48L25.416 25.5H20.196ZM0.287994 25.5L10.224 0.300049H15.156L5.50799 25.5H0.287994ZM4.96799 19.9561V15.6001H20.772V19.9561H4.96799Z" />
    <path d="M0 13H45V17H0V13Z" />
  </svg>

  <svg viewBox="0 0 43 26" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'decoration-none'">
    <path
      d="M38.2365 25.5001L38.0925 21.8641V16.3921C38.0925 15.3121 37.9725 14.3881 37.7325 13.6201C37.5165 12.8521 37.1565 12.2641 36.6525 11.8561C36.1725 11.4241 35.5125 11.2081 34.6725 11.2081C33.9045 11.2081 33.2085 11.3761 32.5845 11.7121C31.9605 12.0481 31.4325 12.5641 31.0005 13.2601L26.8245 11.8201C27.1605 11.0521 27.6525 10.3081 28.3005 9.58812C28.9485 8.84412 29.7885 8.24412 30.8205 7.78812C31.8765 7.30812 33.1605 7.06812 34.6725 7.06812C36.4965 7.06812 38.0085 7.41612 39.2085 8.11212C40.4325 8.80812 41.3325 9.79212 41.9085 11.0641C42.5085 12.3121 42.7965 13.8001 42.7725 15.5281L42.6645 25.5001H38.2365ZM33.1965 25.9321C31.0365 25.9321 29.3565 25.4521 28.1565 24.4921C26.9805 23.5321 26.3925 22.1761 26.3925 20.4241C26.3925 18.5041 27.0285 17.0641 28.3005 16.1041C29.5965 15.1201 31.4085 14.6281 33.7365 14.6281H38.3085V18.1561H35.3925C33.8325 18.1561 32.7525 18.3481 32.1525 18.7321C31.5525 19.0921 31.2525 19.6081 31.2525 20.2801C31.2525 20.8081 31.4925 21.2281 31.9725 21.5401C32.4525 21.8281 33.1245 21.9721 33.9885 21.9721C34.7805 21.9721 35.4765 21.7921 36.0765 21.4321C36.7005 21.0481 37.1925 20.5681 37.5525 19.9921C37.9125 19.3921 38.0925 18.7801 38.0925 18.1561H39.2445C39.2445 20.6041 38.7765 22.5121 37.8405 23.8801C36.9285 25.2481 35.3805 25.9321 33.1965 25.9321Z" />
    <path
      d="M20.196 25.5L10.548 0.300049H15.48L25.416 25.5H20.196ZM0.288002 25.5L10.224 0.300049H15.156L5.508 25.5H0.288002ZM4.968 19.9561V15.6001H20.772V19.9561H4.968Z" />
  </svg>

  <svg width="43" height="33" viewBox="0 0 43 33" xmlns="http://www.w3.org/2000/svg"
    v-else-if="props.nome === 'decoration-underline'">
    <path d="M0 30H43V32H0V30Z" />
    <path
      d="M38.2365 25.5001L38.0925 21.8641V16.3921C38.0925 15.3121 37.9725 14.3881 37.7325 13.6201C37.5165 12.8521 37.1565 12.2641 36.6525 11.8561C36.1725 11.4241 35.5125 11.2081 34.6725 11.2081C33.9045 11.2081 33.2085 11.3761 32.5845 11.7121C31.9605 12.0481 31.4325 12.5641 31.0005 13.2601L26.8245 11.8201C27.1605 11.0521 27.6525 10.3081 28.3005 9.58812C28.9485 8.84412 29.7885 8.24412 30.8205 7.78812C31.8765 7.30812 33.1605 7.06812 34.6725 7.06812C36.4965 7.06812 38.0085 7.41612 39.2085 8.11212C40.4325 8.80812 41.3325 9.79212 41.9085 11.0641C42.5085 12.3121 42.7965 13.8001 42.7725 15.5281L42.6645 25.5001H38.2365ZM33.1965 25.9321C31.0365 25.9321 29.3565 25.4521 28.1565 24.4921C26.9805 23.5321 26.3925 22.1761 26.3925 20.4241C26.3925 18.5041 27.0285 17.0641 28.3005 16.1041C29.5965 15.1201 31.4085 14.6281 33.7365 14.6281H38.3085V18.1561H35.3925C33.8325 18.1561 32.7525 18.3481 32.1525 18.7321C31.5525 19.0921 31.2525 19.6081 31.2525 20.2801C31.2525 20.8081 31.4925 21.2281 31.9725 21.5401C32.4525 21.8281 33.1245 21.9721 33.9885 21.9721C34.7805 21.9721 35.4765 21.7921 36.0765 21.4321C36.7005 21.0481 37.1925 20.5681 37.5525 19.9921C37.9125 19.3921 38.0925 18.7801 38.0925 18.1561H39.2445C39.2445 20.6041 38.7765 22.5121 37.8405 23.8801C36.9285 25.2481 35.3805 25.9321 33.1965 25.9321Z" />
    <path
      d="M20.196 25.5L10.548 0.300049H15.48L25.416 25.5H20.196ZM0.287994 25.5L10.224 0.300049H15.156L5.50799 25.5H0.287994ZM4.96799 19.9561V15.6001H20.772V19.9561H4.96799Z" />
    <path d="M0 29H43V33H0V29Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 424.98 424.98" v-else-if="props.nome === 'dinheiro'">
    <path d="M241.395,282.304c-1.587-1.738-3.595-3.038-5.67-4.121c-4.518-2.356-9.459-3.785-14.365-5.075v38.016
				c7.963-0.9,17.105-3.79,21.286-11.224l0,0c1.996-3.551,2.393-7.914,1.58-11.867C243.785,285.891,242.874,283.925,241.395,282.304
				z"></path>
    <path d="M242.604,299.973c0.016-0.027,0.025-0.044,0.042-0.073l0,0C242.632,299.924,242.618,299.948,242.604,299.973z">
    </path>
    <path d="M184.009,221.532c-1.369,1.999-2.228,4.27-2.465,6.684c-0.237,2.419-0.104,5.11,0.815,7.387
				c0.875,2.17,2.708,3.772,4.6,5.062c2.123,1.444,4.458,2.572,6.836,3.528c1.995,0.803,4.239,1.571,6.658,2.313v-34.4
				C194.342,213.41,187.665,216.194,184.009,221.532z"></path>
    <path d="M242.804,299.619c-0.05,0.089-0.104,0.182-0.157,0.28l0,0C242.709,299.785,242.758,299.701,242.804,299.619z">
    </path>
    <path d="M243.004,299.263C243.017,299.239,243.019,299.237,243.004,299.263L243.004,299.263z"></path>
    <path d="M234.753,92.469c32.329-27.158,53.931-88.341,40.637-91.017c-17.664-3.557-56.022,12.04-74.562,14.788
				c-26.296,3.175-54.936-28.515-71.012-10.851c-13.071,14.362,9.371,66.592,44.482,89.346
				C69.546,146.219-77.69,404.673,179.171,423.426C534.582,449.375,356.615,142.639,234.753,92.469z M265.276,296.298
				c-1.093,10.076-6.433,19.188-14.415,25.374c-8.428,6.532-18.999,9.57-29.502,10.421v11.133c0,2.979-1.301,5.86-3.531,7.832
				c-3.065,2.712-7.569,3.381-11.289,1.667c-3.673-1.69-6.086-5.457-6.086-9.499v-12.168c-1.801-0.342-3.589-0.749-5.356-1.234
				c-9.816-2.697-18.921-7.954-25.572-15.732c-3.313-3.877-6.014-8.276-7.882-13.025c-0.488-1.241-0.923-2.505-1.304-3.783
				c-0.345-1.157-0.701-2.333-0.824-3.539c-0.207-2.023,0.194-4.087,1.137-5.889c1.938-3.707,6.022-5.946,10.192-5.574
				c4.104,0.364,7.701,3.212,8.993,7.124c0.398,1.205,0.668,2.44,1.115,3.632c0.443,1.184,0.978,2.335,1.607,3.431
				c1.242,2.158,2.798,4.148,4.59,5.875c3.694,3.559,8.399,5.872,13.304,7.248v-41.362c-9.591-2.483-19.491-5.69-27.411-11.848
				c-3.849-2.994-7.115-6.714-9.254-11.117c-2.257-4.647-3.192-9.824-3.23-14.966c-0.039-5.221,0.953-10.396,3.131-15.153
				c2.04-4.454,4.977-8.453,8.578-11.768c7.7-7.087,17.928-11.04,28.187-12.492v-0.91v-10.647c0-2.978,1.301-5.86,3.531-7.832
				c3.066-2.711,7.568-3.381,11.289-1.667c3.672,1.691,6.086,5.457,6.086,9.499v10.647v0.847c1.367,0.172,2.73,0.378,4.086,0.624
				c10.074,1.823,19.927,5.983,27.294,13.246c3.49,3.44,6.347,7.539,8.356,12.009c0.561,1.247,1.052,2.523,1.477,3.824
				c0.396,1.213,0.794,2.462,0.983,3.728c0.302,2.021-0.006,4.109-0.871,5.958c-1.772,3.788-5.746,6.2-9.927,6.021
				c-4.108-0.179-7.83-2.854-9.301-6.694c-0.438-1.142-0.657-2.351-1.104-3.49c-0.451-1.153-1.035-2.253-1.708-3.292
				c-1.308-2.02-3.003-3.752-4.938-5.179c-4.19-3.094-9.272-4.706-14.35-5.607v39.582c6.035,1.445,12.075,3.021,17.857,5.301
				c8.739,3.446,17.02,8.73,21.79,17.062c-0.74-1.298-1.46-2.563,0.025,0.043c1.458,2.56,0.762,1.34,0.03,0.057
				C264.854,280.704,266.101,288.701,265.276,296.298z"></path>
    <path d="M242.493,300.169c-0.061,0.109-0.114,0.205-0.156,0.278C242.373,300.384,242.427,300.289,242.493,300.169z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.516 461.516" v-else-if="props.nome === 'lupa'">
    <path
      d="m185.746 371.332c41.251.001 81.322-13.762 113.866-39.11l122.778 122.778c9.172 8.858 23.787 8.604 32.645-.568 8.641-8.947 8.641-23.131 0-32.077l-122.778-122.778c62.899-80.968 48.252-197.595-32.716-260.494s-197.594-48.252-260.493 32.716-48.252 197.595 32.716 260.494c32.597 25.323 72.704 39.06 113.982 39.039zm-98.651-284.273c54.484-54.485 142.82-54.486 197.305-.002s54.486 142.82.002 197.305-142.82 54.486-197.305.002c-.001-.001-.001-.001-.002-.002-54.484-54.087-54.805-142.101-.718-196.585.239-.24.478-.479.718-.718z">
    </path>
  </svg>

  <svg viewBox="0 0 154 157" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'link-bio'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12 0C5.37256 0 0 5.37256 0 12V22H154V12C154 5.37256 148.627 0 142 0H12ZM16.5 16C18.9852 16 21 13.9852 21 11.5C21 9.01477 18.9852 7 16.5 7C14.0148 7 12 9.01477 12 11.5C12 13.9852 14.0148 16 16.5 16ZM35 11.5C35 13.9852 32.9852 16 30.5 16C28.0148 16 26 13.9852 26 11.5C26 9.01477 28.0148 7 30.5 7C32.9852 7 35 9.01477 35 11.5ZM44.5 16C46.9852 16 49 13.9852 49 11.5C49 9.01477 46.9852 7 44.5 7C42.0148 7 40 9.01477 40 11.5C40 13.9852 42.0148 16 44.5 16Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M154 29H0V145C0 151.627 5.37256 157 12 157H142C148.627 157 154 151.627 154 145V29ZM48 110C45.7909 110 44 111.791 44 114V120C44 122.209 45.7909 124 48 124H106C108.209 124 110 122.209 110 120V114C110 111.791 108.209 110 106 110H48ZM64 52.5C64 45.5964 69.5964 40 76.5 40H77.5C84.4036 40 90 45.5964 90 52.5C90 59.4036 84.4036 65 77.5 65H76.5C69.5964 65 64 59.4036 64 52.5ZM48 91C45.7909 91 44 92.7909 44 95V101C44 103.209 45.7909 105 48 105H106C108.209 105 110 103.209 110 101V95C110 92.7909 108.209 91 106 91H48ZM44 76C44 73.7909 45.7909 72 48 72H106C108.209 72 110 73.7909 110 76V82C110 84.2091 108.209 86 106 86H48C45.7909 86 44 84.2091 44 82V76ZM55 129C51.6863 129 49 131.686 49 135C49 138.314 51.6863 141 55 141H98C101.314 141 104 138.314 104 135C104 131.686 101.314 129 98 129H55Z" />
  </svg>

  <svg viewBox="0 0 154 157" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'pagina-captura'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12 0C5.37256 0 0 5.37256 0 12V22H154V12C154 5.37256 148.627 0 142 0H12ZM16.5 16C18.9852 16 21 13.9852 21 11.5C21 9.01477 18.9852 7 16.5 7C14.0148 7 12 9.01477 12 11.5C12 13.9852 14.0148 16 16.5 16ZM35 11.5C35 13.9852 32.9852 16 30.5 16C28.0148 16 26 13.9852 26 11.5C26 9.01477 28.0148 7 30.5 7C32.9852 7 35 9.01477 35 11.5ZM44.5 16C46.9852 16 49 13.9852 49 11.5C49 9.01477 46.9852 7 44.5 7C42.0148 7 40 9.01477 40 11.5C40 13.9852 42.0148 16 44.5 16Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M154 29H0V145C0 151.627 5.37256 157 12 157H142C148.627 157 154 151.627 154 145V29ZM16 91C13.7909 91 12 92.7909 12 95V101C12 103.209 13.7909 105 16 105H74C76.2091 105 78 103.209 78 101V95C78 92.7909 76.2091 91 74 91H16ZM91 51C91 48.7909 92.7909 47 95 47H139C141.209 47 143 48.7909 143 51V135C143 137.209 141.209 139 139 139H95C92.7909 139 91 137.209 91 135V51ZM16 72C13.7909 72 12 73.7909 12 76V82C12 84.2091 13.7909 86 16 86H74C76.2091 86 78 84.2091 78 82V76C78 73.7909 76.2091 72 74 72H16ZM12 57C12 54.7909 13.7909 53 16 53H74C76.2091 53 78 54.7909 78 57V63C78 65.2091 76.2091 67 74 67H16C13.7909 67 12 65.2091 12 63V57ZM18 115C14.6863 115 12 117.686 12 121C12 124.314 14.6863 127 18 127H61C64.3137 127 67 124.314 67 121C67 117.686 64.3137 115 61 115H18Z" />
  </svg>

  <svg viewBox="0 0 154 157" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'pagina-vendas'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12 0C5.37256 0 0 5.37256 0 12V22H154V12C154 5.37256 148.627 0 142 0H12ZM16.5 16C18.9852 16 21 13.9852 21 11.5C21 9.01477 18.9852 7 16.5 7C14.0148 7 12 9.01477 12 11.5C12 13.9852 14.0148 16 16.5 16ZM35 11.5C35 13.9852 32.9852 16 30.5 16C28.0148 16 26 13.9852 26 11.5C26 9.01477 28.0148 7 30.5 7C32.9852 7 35 9.01477 35 11.5ZM44.5 16C46.9852 16 49 13.9852 49 11.5C49 9.01477 46.9852 7 44.5 7C42.0148 7 40 9.01477 40 11.5C40 13.9852 42.0148 16 44.5 16Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M154 29H0V145C0 151.627 5.37256 157 12 157H142C148.627 157 154 151.627 154 145V29ZM37 45C34.7909 45 33 46.7909 33 49V91C33 93.2091 34.7909 95 37 95H116C118.209 95 120 93.2091 120 91V49C120 46.7909 118.209 45 116 45H37ZM16 134C16 130.134 19.134 127 23 127H37C40.866 127 44 130.134 44 134C44 137.866 40.866 141 37 141H23C19.134 141 16 137.866 16 134ZM57 127C53.134 127 50 130.134 50 134C50 137.866 53.134 141 57 141H109C112.866 141 116 137.866 116 134C116 130.134 112.866 127 109 127H57ZM143 134C143 130.134 139.866 127 136 127H129C125.134 127 122 130.134 122 134C122 137.866 125.134 141 129 141H136C139.866 141 143 137.866 143 134ZM58 104C55.2385 104 53 106.239 53 109C53 111.761 55.2385 114 58 114H95C97.7615 114 100 111.761 100 109C100 106.239 97.7615 104 95 104H58Z" />
  </svg>

  <svg viewBox="0 0 337 343" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'todos'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12 0C5.37256 0 0 5.37256 0 12V22H154V12C154 5.37256 148.627 0 142 0H12ZM16.5 16C18.9852 16 21 13.9852 21 11.5C21 9.01477 18.9852 7 16.5 7C14.0148 7 12 9.01477 12 11.5C12 13.9852 14.0148 16 16.5 16ZM35 11.5C35 13.9852 32.9852 16 30.5 16C28.0148 16 26 13.9852 26 11.5C26 9.01477 28.0148 7 30.5 7C32.9852 7 35 9.01477 35 11.5ZM44.5 16C46.9852 16 49 13.9852 49 11.5C49 9.01477 46.9852 7 44.5 7C42.0148 7 40 9.01477 40 11.5C40 13.9852 42.0148 16 44.5 16Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M154 29H0V145C0 151.627 5.37256 157 12 157H142C148.627 157 154 151.627 154 145V29ZM37 45C34.7909 45 33 46.7909 33 49V91C33 93.2091 34.7909 95 37 95H116C118.209 95 120 93.2091 120 91V49C120 46.7909 118.209 45 116 45H37ZM16 134C16 130.134 19.134 127 23 127H37C40.866 127 44 130.134 44 134C44 137.866 40.866 141 37 141H23C19.134 141 16 137.866 16 134ZM57 127C53.134 127 50 130.134 50 134C50 137.866 53.134 141 57 141H109C112.866 141 116 137.866 116 134C116 130.134 112.866 127 109 127H57ZM143 134C143 130.134 139.866 127 136 127H129C125.134 127 122 130.134 122 134C122 137.866 125.134 141 129 141H136C139.866 141 143 137.866 143 134ZM58 104C55.2385 104 53 106.239 53 109C53 111.761 55.2385 114 58 114H95C97.7615 114 100 111.761 100 109C100 106.239 97.7615 104 95 104H58Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M195 0C188.373 0 183 5.37256 183 12V22H337V12C337 5.37256 331.627 0 325 0H195ZM199.5 16C201.985 16 204 13.9852 204 11.5C204 9.01477 201.985 7 199.5 7C197.015 7 195 9.01477 195 11.5C195 13.9852 197.015 16 199.5 16ZM218 11.5C218 13.9852 215.985 16 213.5 16C211.015 16 209 13.9852 209 11.5C209 9.01477 211.015 7 213.5 7C215.985 7 218 9.01477 218 11.5ZM227.5 16C229.985 16 232 13.9852 232 11.5C232 9.01477 229.985 7 227.5 7C225.015 7 223 9.01477 223 11.5C223 13.9852 225.015 16 227.5 16Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M337 29H183V145C183 151.627 188.373 157 195 157H325C331.627 157 337 151.627 337 145V29ZM199 91C196.791 91 195 92.7909 195 95V101C195 103.209 196.791 105 199 105H257C259.209 105 261 103.209 261 101V95C261 92.7909 259.209 91 257 91H199ZM274 51C274 48.7909 275.791 47 278 47H322C324.209 47 326 48.7909 326 51V135C326 137.209 324.209 139 322 139H278C275.791 139 274 137.209 274 135V51ZM199 72C196.791 72 195 73.7909 195 76V82C195 84.2091 196.791 86 199 86H257C259.209 86 261 84.2091 261 82V76C261 73.7909 259.209 72 257 72H199ZM195 57C195 54.7909 196.791 53 199 53H257C259.209 53 261 54.7909 261 57V63C261 65.2091 259.209 67 257 67H199C196.791 67 195 65.2091 195 63V57ZM201 115C197.686 115 195 117.686 195 121C195 124.314 197.686 127 201 127H244C247.314 127 250 124.314 250 121C250 117.686 247.314 115 244 115H201Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M12 186C5.37256 186 0 191.373 0 198V208H154V198C154 191.373 148.627 186 142 186H12ZM16.5 202C18.9852 202 21 199.985 21 197.5C21 195.015 18.9852 193 16.5 193C14.0148 193 12 195.015 12 197.5C12 199.985 14.0148 202 16.5 202ZM35 197.5C35 199.985 32.9852 202 30.5 202C28.0148 202 26 199.985 26 197.5C26 195.015 28.0148 193 30.5 193C32.9852 193 35 195.015 35 197.5ZM44.5 202C46.9852 202 49 199.985 49 197.5C49 195.015 46.9852 193 44.5 193C42.0148 193 40 195.015 40 197.5C40 199.985 42.0148 202 44.5 202Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M154 215H0V331C0 337.627 5.37256 343 12 343H142C148.627 343 154 337.627 154 331V215ZM48 296C45.7909 296 44 297.791 44 300V306C44 308.209 45.7909 310 48 310H106C108.209 310 110 308.209 110 306V300C110 297.791 108.209 296 106 296H48ZM64 238.5C64 231.596 69.5964 226 76.5 226H77.5C84.4036 226 90 231.596 90 238.5C90 245.404 84.4036 251 77.5 251H76.5C69.5964 251 64 245.404 64 238.5ZM48 277C45.7909 277 44 278.791 44 281V287C44 289.209 45.7909 291 48 291H106C108.209 291 110 289.209 110 287V281C110 278.791 108.209 277 106 277H48ZM44 262C44 259.791 45.7909 258 48 258H106C108.209 258 110 259.791 110 262V268C110 270.209 108.209 272 106 272H48C45.7909 272 44 270.209 44 268V262ZM55 315C51.6863 315 49 317.686 49 321C49 324.314 51.6863 327 55 327H98C101.314 327 104 324.314 104 321C104 317.686 101.314 315 98 315H55Z" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M195 186C188.373 186 183 191.373 183 198V208H337V198C337 191.373 331.627 186 325 186H195ZM199.5 202C201.985 202 204 199.985 204 197.5C204 195.015 201.985 193 199.5 193C197.015 193 195 195.015 195 197.5C195 199.985 197.015 202 199.5 202ZM218 197.5C218 199.985 215.985 202 213.5 202C211.015 202 209 199.985 209 197.5C209 195.015 211.015 193 213.5 193C215.985 193 218 195.015 218 197.5ZM227.5 202C229.985 202 232 199.985 232 197.5C232 195.015 229.985 193 227.5 193C225.015 193 223 195.015 223 197.5C223 199.985 225.015 202 227.5 202ZM337 215H183V331C183 337.627 188.373 343 195 343H325C331.627 343 337 337.627 337 331V215Z" />
  </svg>

  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'casa'">
    <path
      d="m498.195312 222.695312c-.011718-.011718-.023437-.023437-.035156-.035156l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.8125-33.328126-13.8125-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.140626.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.445312 13.238281 31.277344 13.746093.480468.046876.964843.070313 1.453124.070313h8.324219v153.699219c0 30.414062 24.746094 55.160156 55.167969 55.160156h81.710938c8.28125 0 15-6.714844 15-15v-120.5c0-13.878906 11.289062-25.167969 25.167968-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.285156 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.160156v-153.699219h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.808594 18.359375-18.371093 18.367187-48.253906.023437-66.636719zm0 0">
    </path>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'camadas'">
    <path clip-rule="evenodd"
      d="m13.3951 1.32445c-.8787-.4326-1.9115-.432601-2.7902 0l-8.75201 4.30902c-1.137183.55988-1.13719 2.17317 0 2.73306l8.75201 4.30897c.8787.4327 1.9115.4327 2.7902.0001l8.752-4.30907c1.1372-.55988 1.1372-2.17317 0-2.73306zm-11.492 9.28425c.13926-.0686.30246-.0686.44171 0l8.26009 4.0668c.8787.4326 1.9115.4326 2.7902 0l8.2601-4.0668c.1392-.0686.3024-.0686.4417 0l.0502.0247c1.1372.5599 1.1372 2.1732 0 2.7331l-8.752 4.309c-.8787.4326-1.9115.4326-2.7902 0l-8.75201-4.309c-1.13719-.5599-1.137183-2.1732 0-2.7331zm.44171 5.0001c-.13925-.0686-.30245-.0686-.44171 0l-.05021.0247c-1.137183.5599-1.13719 2.1732 0 2.7331l8.75201 4.309c.8787.4326 1.9115.4326 2.7902 0l8.752-4.309c1.1372-.5599 1.1372-2.1732 0-2.7331l-.0502-.0247c-.1393-.0686-.3025-.0686-.4417 0l-8.2601 4.0668c-.8787.4326-1.9115.4326-2.7902 0z"
      fill-rule="evenodd"></path>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'comunicado'">
    <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z">
    </path>
    <path
      d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else-if="props.nome === 'medalha'">
    <path d="M255.992,0c-97.056,0-176,78.944-176,176s78.944,176,176,176c97.024,0,176-78.944,176-176S353.016,0,255.992,0z
			 M348.504,151.104l-30.4,38.08l3.328,49.76c0.384,5.536-2.144,10.88-6.72,14.112c-2.784,1.952-6.016,2.944-9.28,2.944
			c-2.112,0-4.256-0.416-6.24-1.28l-43.2-18.336l-43.168,18.336c-5.12,2.176-11.008,1.568-15.52-1.664
			c-4.544-3.232-7.072-8.576-6.72-14.112l3.328-49.76l-30.4-38.08c-3.392-4.224-4.384-9.888-2.688-15.04
			c1.696-5.152,5.888-9.056,11.136-10.432l45.376-11.872l24.896-41.92c5.76-9.728,21.76-9.728,27.488,0l24.896,41.92l45.408,11.872
			c5.216,1.376,9.408,5.312,11.136,10.432C352.888,141.184,351.864,146.848,348.504,151.104z"></path>
    <path d="M75.992,279.776L2.136,408c-3.232,5.632-2.752,12.64,1.184,17.76s10.56,7.424,16.832,5.696l93.952-25.248l24.992,93.952
			c1.664,6.24,6.944,10.88,13.344,11.744c0.704,0.064,1.44,0.096,2.112,0.096c5.664,0,10.976-3.008,13.856-8l69.856-120.896
			C168.856,377.216,109.208,337.12,75.992,279.776z"></path>
    <path d="M509.88,408l-73.888-128.224c-33.184,57.344-92.864,97.44-162.272,103.328L343.576,504c2.88,4.992,8.192,8,13.856,8
			c0.672,0,1.408-0.032,2.08-0.128c6.432-0.864,11.68-5.504,13.376-11.744l24.992-93.952l93.952,25.248
			c6.272,1.728,12.864-0.576,16.832-5.696C512.632,420.64,513.08,413.632,509.88,408z"></path>
  </svg>

  <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'sino'">
    <path
      d="m55.434 72.521c0 3.712-1.979 6.553-5.193 8.41-3.213 1.856-7.172 1.856-10.379 0-3.215-1.857-5.193-4.698-5.193-8.41">
    </path>
    <path
      d="m45.049 7.708c2.832 0 5.113 1.746 5.113 4.431 0 1.69.059 2.951 1.121 3.274 9.031 2.727 14.822 9.119 14.822 17.42v13.952c0 6.392 2.615 7.686 5.906 10.131 4.766 3.535 3.678 10.896-1.791 10.891h-50.34c-5.469.005-6.557-7.356-1.791-10.891 3.287-2.445 5.906-3.739 5.906-10.131v-13.952c0-8.301 5.791-14.693 14.822-17.42 1.059-.323 1.121-1.584 1.121-3.274.001-2.685 2.283-4.431 5.111-4.431z">
    </path>
  </svg>

  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'alunos'">
    <path
      d="m6.8 24.1c0-2.2.8-4.2 2-5.7-.5-.2-1.1-.3-1.6-.3-2.9 0-5.3 2.4-5.3 5.3-.1.6.4 1.1 1 1.1h3.8c.1-.1.1-.3.1-.4z">
    </path>
    <path
      d="m7.3 17.5c1.5 0 2.8-.9 3.3-2.2-.8-1.1-1.2-2.4-1.2-3.9 0-.1 0-.2 0-.4-.6-.4-1.3-.7-2.1-.7-2 0-3.5 1.6-3.5 3.6-.1 2 1.5 3.6 3.5 3.6z">
    </path>
    <path d="m24.7 18.1c-.6 0-1.1.1-1.6.3 1.3 1.6 2 3.5 2 5.7v.4h3.9c.6 0 1.1-.5 1-1.1 0-2.9-2.4-5.3-5.3-5.3z"></path>
    <path
      d="m24.7 17.5c2 0 3.5-1.6 3.5-3.6s-1.6-3.6-3.5-3.6c-.8 0-1.5.2-2.1.7v.4c0 1.4-.5 2.8-1.2 3.9.6 1.3 1.8 2.2 3.3 2.2z">
    </path>
    <path d="m16 17c-3.9 0-7.2 3.2-7.2 7.2-.1.6.4 1.2 1 1.2h12.3c.6 0 1.1-.6 1-1.2.1-4-3.2-7.2-7.1-7.2z"></path>
    <path d="m16 16.1c2.6 0 4.7-2.1 4.7-4.7s-2.1-4.7-4.7-4.7-4.7 2.1-4.7 4.7 2.1 4.7 4.7 4.7z"></path>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'mais'">
    <path
      d="m19 9v9c1.6569 0 3-1.3431 3-3v-10c0-1.65685-1.3431-3-3-3h-10c-1.65685 0-3 1.34315-3 3h9c2.2091 0 4 1.79086 4 4z">
    </path>
    <path clip-rule="evenodd"
      d="m2 19c0 1.6569 1.34315 3 3 3h10c1.6569 0 3-1.3431 3-3v-10c0-1.65685-1.3431-3-3-3h-10c-1.65685 0-3 1.34315-3 3zm4-5c0-.5523.44772-1 1-1h2v-2c0-.5523.44772-1 1-1 .5523 0 1 .4477 1 1v2h2c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2v2c0 .5523-.4477 1-1 1-.55229 0-1-.4477-1-1v-2h-2c-.55228 0-1-.4477-1-1z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.003 512.003" v-else-if="props.nome === 'oferta'">
    <path d="M477.958,262.633c-2.06-4.215-2.06-9.049,0-13.263l19.096-39.065c10.632-21.751,2.208-47.676-19.178-59.023l-38.41-20.38
			c-4.144-2.198-6.985-6.11-7.796-10.729l-7.512-42.829c-4.183-23.846-26.241-39.87-50.208-36.479l-43.053,6.09
			c-4.647,0.656-9.242-0.838-12.613-4.099l-31.251-30.232c-17.401-16.834-44.661-16.835-62.061,0L193.72,42.859
			c-3.372,3.262-7.967,4.753-12.613,4.099l-43.053-6.09c-23.975-3.393-46.025,12.633-50.208,36.479l-7.512,42.827
			c-0.811,4.62-3.652,8.531-7.795,10.73l-38.41,20.38c-21.386,11.346-29.81,37.273-19.178,59.024l19.095,39.064
			c2.06,4.215,2.06,9.049,0,13.263l-19.096,39.064c-10.632,21.751-2.208,47.676,19.178,59.023l38.41,20.38
			c4.144,2.198,6.985,6.11,7.796,10.729l7.512,42.829c3.808,21.708,22.422,36.932,43.815,36.93c2.107,0,4.245-0.148,6.394-0.452
			l43.053-6.09c4.643-0.659,9.241,0.838,12.613,4.099l31.251,30.232c8.702,8.418,19.864,12.626,31.03,12.625
			c11.163-0.001,22.332-4.209,31.03-12.625l31.252-30.232c3.372-3.261,7.968-4.751,12.613-4.099l43.053,6.09
			c23.978,3.392,46.025-12.633,50.208-36.479l7.513-42.827c0.811-4.62,3.652-8.531,7.795-10.73l38.41-20.38
			c21.386-11.346,29.81-37.273,19.178-59.024L477.958,262.633z M196.941,123.116c29.852,0,54.139,24.287,54.139,54.139
			s-24.287,54.139-54.139,54.139s-54.139-24.287-54.139-54.139S167.089,123.116,196.941,123.116z M168.997,363.886
			c-2.883,2.883-6.662,4.325-10.44,4.325s-7.558-1.441-10.44-4.325c-5.766-5.766-5.766-15.115,0-20.881l194.889-194.889
			c5.765-5.766,15.115-5.766,20.881,0c5.766,5.766,5.766,15.115,0,20.881L168.997,363.886z M315.061,388.888
			c-29.852,0-54.139-24.287-54.139-54.139s24.287-54.139,54.139-54.139c29.852,0,54.139,24.287,54.139,54.139
			S344.913,388.888,315.061,388.888z"></path>
    <path d="M315.061,310.141c-13.569,0-24.609,11.039-24.609,24.608s11.039,24.608,24.609,24.608
			c13.569,0,24.608-11.039,24.608-24.608S328.63,310.141,315.061,310.141z"></path>
    <path d="M196.941,152.646c-13.569,0-24.608,11.039-24.608,24.608c0,13.569,11.039,24.609,24.608,24.609
			c13.569,0,24.609-11.039,24.609-24.609C221.549,163.686,210.51,152.646,196.941,152.646z"></path>
  </svg>

  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'curso'">
    <path
      d="m11 9c-2.2055664 0-4 1.7939453-4 4s1.7944336 4 4 4 4-1.7939453 4-4-1.7944336-4-4-4zm-1.5 6.8681641v-5.7363281l4.3027344 2.868164zm17.5-13.8681641h-22c-2.2055664 0-4 1.7939453-4 4v14c0 2.2060547 1.7944336 4 4 4h22c2.2055664 0 4-1.7939453 4-4v-14c0-2.2060547-1.7944336-4-4-4zm-16 17c-3.3085938 0-6-2.6914063-6-6s2.6914063-6 6-6 6 2.6914063 6 6-2.6914062 6-6 6zm16-1h-8v-2h8zm0-4h-8v-2h8zm0-4h-8v-2h8zm-8 18v-2h-6v2h-4v2h14v-2z">
    </path>
  </svg>

  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'modulo'">
    <path
      d="m297.733 131.976h199.267c8.284 0 15-6.716 15-15v-83.468c0-8.284-6.716-15-15-15h-199.267c-8.284 0-15 6.716-15 15v26.734h-159.795c-8.284 0-15 6.716-15 15v110.202h-92.938c-8.284 0-15 6.716-15 15v83.468c0 8.284 6.716 15 15 15h84.633v67.291h-84.633c-8.284 0-15 6.716-15 15v83.468c0 8.284 6.716 15 15 15h199.266c8.284 0 15-6.716 15-15v-26.734h99.165c6.897 31.721 35.178 55.557 68.936 55.557 38.905 0 70.557-31.651 70.557-70.557 0-33.758-23.835-62.039-55.557-68.936v-55.089h84.633c8.284 0 15-6.716 15-15v-83.468c0-8.284-6.716-15-15-15h-199.267c-8.284 0-15 6.716-15 15v83.468c0 8.284 6.716 15 15 15h84.634v55.088c-26.884 5.845-48.091 27.052-53.936 53.936h-99.165v-26.734c0-8.284-6.716-15-15-15h-84.633v-67.291h84.633c8.284 0 15-6.716 15-15v-83.468c0-8.284-6.716-15-15-15h-76.328v-95.201h144.795v26.734c0 8.284 6.716 15 15 15z">
    </path>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'webhook'">
    <path d="m14 21a5 5 0 1 0 .5-8.331l-2.5-3.669"></path>
    <path d="m17 9a5 5 0 1 0 -7.5 4.331l-2.5 3.669"></path>
    <path d="m3.43 13.5a5 5 0 1 0 8.57 3.5h5"></path>
    <circle cx="7" cy="17" r="2"></circle>
    <circle cx="17" cy="17" r="2"></circle>
    <circle cx="12" cy="9" r="2"></circle>
  </svg>

  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'relogio'">
    <path
      d="m16 5.5a10.5 10.5 0 1 0 10.5 10.5 10.51148 10.51148 0 0 0 -10.5-10.5zm4.78 14.12a.99885.99885 0 0 1 -.78.38.96239.96239 0 0 1 -.62012-.22l-4-3a1.00078 1.00078 0 0 1 -.37988-.78v-7a1 1 0 0 1 2 0v6.52l3.62012 2.7a.98171.98171 0 0 1 .15988 1.4z">
    </path>
    <path
      d="m16 1a15 15 0 1 0 15 15 15.01828 15.01828 0 0 0 -15-15zm0 27.56665a12.56665 12.56665 0 1 1 12.56665-12.56665 12.57878 12.57878 0 0 1 -12.56665 12.56665z">
    </path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459.904 459.904" v-else-if="props.nome === 'upload'">
    <path d="M123.465,168.28h46.543v138.07c0,14.008,11.358,25.352,25.352,25.352h69.2c13.993,0,25.352-11.343,25.352-25.352V168.28
			h46.527c7.708,0,14.637-4.641,17.601-11.764c2.933-7.094,1.301-15.295-4.145-20.741L243.413,29.28
			c-7.437-7.422-19.485-7.422-26.938,0L110.011,135.775c-5.447,5.446-7.079,13.633-4.13,20.741
			C108.843,163.625,115.757,168.28,123.465,168.28z"></path>
    <path d="M437.036,220.029c-12.617,0-22.852,10.237-22.852,22.867v95.615c0,28.643-23.317,51.944-51.961,51.944H97.679
			c-28.644,0-51.945-23.301-51.945-51.944v-95.615c0-12.63-10.251-22.867-22.867-22.867C10.236,220.029,0,230.266,0,242.897v95.615
			c0,53.859,43.818,97.679,97.679,97.679h264.544c53.861,0,97.681-43.819,97.681-97.679v-95.615
			C459.904,230.266,449.667,220.029,437.036,220.029z"></path>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" v-else-if="props.nome === 'personalizar'">
    <path d="M192,0C85.973,0,0,85.973,0,192s85.973,192,192,192c17.707,0,32-14.293,32-32c0-8.32-3.093-15.787-8.32-21.44
			c-5.013-5.653-8-13.013-8-21.227c0-17.707,14.293-32,32-32h37.653c58.88,0,106.667-47.787,106.667-106.667
			C384,76.373,298.027,0,192,0z M74.667,192c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S92.373,192,74.667,192z
			 M138.667,106.667c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S156.373,106.667,138.667,106.667z M245.333,106.667
			c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S263.04,106.667,245.333,106.667z M309.333,192
			c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S327.04,192,309.333,192z"></path>
  </svg>

  <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'ranking'">
    <path d="m9.2 33h-7.2c-.5 0-1-.5-1-1v-7.3c0-.6.5-1 1-1h7v8.3c0 .3.1.7.2 1z"></path>
    <path d="m23 20v12c0 .5-.5 1-1 1h-10c-.5 0-1-.5-1-1v-12c0-.6.5-1 1-1h10c.5 0 1 .4 1 1z"></path>
    <path d="m33 26v6c0 .5-.5 1-1 1h-7.2c.1-.3.2-.7.2-1v-7h7c.5 0 1 .4 1 1z"></path>
    <path
      d="m25.1 8-3.3 3.2.8 4.6c.1.4-.1.8-.4 1s-.8.2-1.1.1l-4.1-2.2-4.1 2.1c-.3.2-.7.2-1-.1-.3-.2-.5-.6-.4-1l.8-4.6-3.4-3.1c-.2-.2-.3-.6-.2-1s.4-.6.8-.7l4.6-.7 2-4.1c.3-.7 1.5-.7 1.8 0l2 4.1 4.6.7c.4.1.7.3.8.7s0 .8-.2 1z">
    </path>
  </svg>

  <svg viewBox="0 0 512.00533 512" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'ultimo'">
    <path
      d="m320 277.335938c-11.796875 0-21.332031 9.558593-21.332031 21.332031v85.335937c0 11.753906-9.558594 21.332032-21.335938 21.332032h-64v-320c0-18.21875-11.605469-34.496094-29.054687-40.554688l-6.316406-2.113281h99.371093c11.777344 0 21.335938 9.578125 21.335938 21.335937v64c0 11.773438 9.535156 21.332032 21.332031 21.332032s21.332031-9.558594 21.332031-21.332032v-64c0-35.285156-28.714843-63.99999975-64-63.99999975h-229.332031c-.8125 0-1.492188.36328175-2.28125.46874975-1.027344-.085937-2.007812-.46874975-3.050781-.46874975-23.53125 0-42.667969 19.13281275-42.667969 42.66406275v384c0 18.21875 11.605469 34.496093 29.054688 40.554687l128.386718 42.796875c4.351563 1.34375 8.679688 1.984375 13.226563 1.984375 23.53125 0 42.664062-19.136718 42.664062-42.667968v-21.332032h64c35.285157 0 64-28.714844 64-64v-85.335937c0-11.773438-9.535156-21.332031-21.332031-21.332031zm0 0">
    </path>
    <path
      d="m505.75 198.253906-85.335938-85.332031c-6.097656-6.101563-15.273437-7.9375-23.25-4.632813-7.957031 3.308594-13.164062 11.09375-13.164062 19.714844v64h-85.332031c-11.777344 0-21.335938 9.554688-21.335938 21.332032 0 11.777343 9.558594 21.332031 21.335938 21.332031h85.332031v64c0 8.621093 5.207031 16.40625 13.164062 19.714843 7.976563 3.304688 17.152344 1.46875 23.25-4.628906l85.335938-85.335937c8.339844-8.339844 8.339844-21.824219 0-30.164063zm0 0">
    </path>
  </svg>

  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'exportar'">
    <path
      d="m432 192h-125.25c-27.99 0-50.75-22.76-50.75-50.75v-125.25c0-5.76 1.52-11.24 4.27-16h-161.52c-19.16 0-34.75 15.59-34.75 34.75v442.5c0 19.16 15.59 34.75 34.75 34.75h314.5c19.16 0 34.75-15.59 34.75-34.75v-289.52c-4.76 2.75-10.24 4.27-16 4.27zm-235.31 100.69c6.25-6.25 16.37-6.25 22.62 0l20.69 20.68v-97.37c0-8.84 7.16-16 16-16s16 7.16 16 16v97.37l20.69-20.68c6.24-6.25 16.38-6.25 22.62 0 6.25 6.24 6.25 16.38 0 22.62l-48 48c-3.373 3.46-8.231 5.022-12.85 4.62-8.012-1.07-5.485-.335-57.77-52.62-6.25-6.24-6.25-16.38 0-22.62zm139.31 139.31h-160c-8.84 0-16-7.16-16-16s7.16-16 16-16h160c8.84 0 16 7.16 16 16s-7.16 16-16 16zm-29.25-272h125.25l-144-144v125.25c0 10.34 8.41 18.75 18.75 18.75z">
    </path>
  </svg>

  <svg viewBox="0 0 227 265" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'loading'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M158.887 45.9321C143.566 41.2623 127.281 40.742 111.694 44.4242C96.1062 48.1065 81.7754 55.8594 70.1642 66.8913C58.5531 77.9232 50.0776 91.839 45.6032 107.218C41.1288 122.596 40.8159 138.887 44.6962 154.426C48.5766 169.965 56.5113 184.196 67.6901 195.666C78.869 207.136 92.8915 215.433 108.326 219.712C123.76 223.99 140.053 224.095 155.542 220.018C171.03 215.94 185.159 207.824 196.486 196.501L226.181 226.202C209.598 242.782 188.912 254.663 166.235 260.633C143.559 266.604 119.704 266.449 97.1069 260.185C74.5096 253.922 53.9795 241.773 37.6127 224.98C21.2458 208.188 9.62873 187.352 3.94753 164.602C-1.73368 141.851 -1.27544 118 5.27543 95.4844C11.8263 72.9687 24.2351 52.5948 41.2349 36.4431C58.2347 20.2914 79.2163 8.94043 102.037 3.5493C124.859 -1.84183 148.702 -1.08002 171.132 5.75695L158.887 45.9321Z" />
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.001 297.001" v-else-if="props.nome === 'menu'">
    <path d="M107.883,0h-85.27C10.144,0,0.001,10.143,0.001,22.612v85.27c0,12.469,10.143,22.612,22.612,22.612h85.27
				c12.469,0,22.612-10.143,22.612-22.612v-85.27C130.493,10.143,120.352,0,107.883,0z"></path>
    <path d="M274.388,0h-85.27c-12.469,0-22.612,10.143-22.612,22.612v85.27c0,12.469,10.143,22.612,22.612,22.612h85.27
				c12.469,0,22.612-10.143,22.612-22.612v-85.27C297,10.143,286.857,0,274.388,0z"></path>
    <path d="M107.883,166.507h-85.27c-12.469,0-22.612,10.142-22.612,22.611v85.27C0.001,286.857,10.144,297,22.613,297h85.27
				c12.469,0,22.612-10.143,22.612-22.612v-85.27C130.493,176.649,120.352,166.507,107.883,166.507z"></path>
    <path d="M274.388,166.507h-85.27c-12.469,0-22.612,10.143-22.612,22.612v85.27c0,12.469,10.143,22.612,22.612,22.612h85.27
				C286.857,297,297,286.857,297,274.388v-85.27C297,176.649,286.857,166.507,274.388,166.507z"></path>
  </svg>

  <svg viewBox="0 0 175 175" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'icone'">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M175 58.3331L116.667 58.3331V-0.000244141L175 -0.000239041V58.3331ZM58.3336 116.667L58.3336 147H116.667V116.667L58.3336 116.667ZM58.3336 175H58.3334H0.000244141L0.00024924 116.667L58.3334 116.667L58.3334 58.3334L116.667 58.3334L116.667 116.667H147V58.3334L175 58.3334V116.667H147V147H116.667V175H58.3336ZM147 147H175V175H147V147Z"
      fill="url(#paint0_linear_4_1195)" />
    <rect x="57.6218" y="57.6218" width="57.622" height="57.6219" rx="28.811" transform="rotate(-180 57.6218 57.6218)"
      fill="url(#paint1_linear_4_1195)" />
    <defs>
      <linearGradient id="paint0_linear_4_1195" x1="87.5001" y1="-0.000244141" x2="87.5001" y2="175"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB628" />
        <stop offset="1" stop-color="#FF285C" />
      </linearGradient>
      <linearGradient id="paint1_linear_4_1195" x1="86.4328" y1="115.244" x2="86.4328" y2="57.6218"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CD0FF" />
        <stop offset="1" stop-color="#5200FF" />
      </linearGradient>
    </defs>
  </svg>

  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else-if="props.nome === 'modo'"><path d="m12 6a6 6 0 1 0 6 6 6 6 0 0 0 -6-6zm1 9a1 1 0 0 1 -1-1v-4a1 1 0 0 1 1-1 3 3 0 0 1 0 6z"></path><path d="m12 5a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1z"></path><path d="m17.66 7.34a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29z"></path><path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z"></path><path d="m18.36 19.36a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path><path d="m12 22a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1z"></path><path d="m5.64 19.36a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24z"></path><path d="m4 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2z"></path><path d="m6.34 7.34a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29z"></path></svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-else-if="props.nome === 'atencao'">
		<path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
			c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
			c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
			 M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
			V317.241z"></path>
  </svg>

</template>

<script setup>
const props = defineProps({
  nome: String
})
</script>
