import { defineStore } from 'pinia'
import apiAreas from '../api/aluno/api-areas'

export const useStoreAreas = defineStore('storeAreas', {
  state: () => {
    return {
      area: null
    }
  },
  actions: {
    async receberPorHotlink(hotlink) {
      try {
        const resp = await apiAreas.receberPorHotlink(hotlink)
        this.area = resp
        return true
      } catch (error) {
        this.area = null
        return false
      }
    },
    async receberPorDominioPersonalizado(dominio) {
      try {
        const resp = await apiAreas.receberPorDominioPersonalizado(dominio)
        this.area = resp
        return true
      } catch (error) {
        this.area = null
        return false
      }
    }
  }
})
