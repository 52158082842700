<template>
  <section v-if="storeAreas.area" class="loading" :class="tela.layout">
    <div class="conteudo" :style="conteudoEstilo">
      <img :src="`${tela.imagemLogo || 'https://arquivos.levelmember.com/imagens/default/logo.svg'}`" />
      <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de carregamento' }}</p>
      <span :style="loadingEstilo"></span>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStoreAreas } from '@stores'

const storeAreas = useStoreAreas()

const tela = computed(() => storeAreas.area?.personalizacao?.telaLoading || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#FFFFFF',
  backgroundImage: `url('${tela.value.imagemFundo || ''}')`
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939'
}))

const loadingEstilo = computed(() => ({
  border: `3px solid ${tela.value.corLoading || '#262836'}`
}))

function atualizarFonte() {
  const fontFamily = tela.value.fonte || 'Inter'
  document.documentElement.style.setProperty('--fonte-tela', fontFamily)

  const url = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}&display=swap`

  const linkExistente = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!linkExistente) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

watch(
  () => storeAreas.area?.personalizacao?.telaLoading,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #e1e3eb;
  position: relative;
  min-height: 100dvh;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 100dvh;
  background-color: #e1e3eb;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.conteudo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 30px 0;
}

.conteudo span {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  border-top: 3px solid transparent !important;
  animation: girando 1s linear infinite;
}
</style>
