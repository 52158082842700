<template>
  <section class="erro">
    <svg viewBox="0 0 1863 307" xmlns="http://www.w3.org/2000/svg">
      <path d="M1811.45 172.431C1811.45 159.79 1813.87 149.334 1818.71 141.062C1823.7 132.79 1830.02 126.626 1837.67 122.568C1845.48 118.51 1853.59 116.481 1862.02 116.481V146.446C1854.84 146.446 1848.05 147.305 1841.65 149.021C1835.41 150.738 1830.34 153.547 1826.44 157.449C1822.53 161.351 1820.58 166.345 1820.58 172.431H1811.45ZM1789.68 234V116.95H1820.58V234H1789.68Z" fill="white" />
      <path d="M1712.96 236.809C1701.42 236.809 1691.19 234.234 1682.3 229.084C1673.4 223.778 1666.38 216.52 1661.23 207.313C1656.23 198.105 1653.74 187.492 1653.74 175.475C1653.74 163.458 1656.31 152.845 1661.46 143.637C1666.77 134.429 1673.95 127.25 1683 122.1C1692.21 116.794 1702.74 114.141 1714.6 114.141C1724.9 114.141 1734.35 116.872 1742.93 122.334C1751.67 127.64 1758.61 135.522 1763.76 145.978C1769.07 156.279 1771.72 168.842 1771.72 183.668H1683L1685.81 180.859C1685.81 186.634 1687.21 191.706 1690.02 196.076C1692.99 200.29 1696.73 203.567 1701.26 205.908C1705.94 208.093 1710.94 209.185 1716.24 209.185C1722.64 209.185 1727.87 207.859 1731.93 205.206C1735.98 202.396 1739.11 198.807 1741.29 194.437L1768.91 205.206C1765.64 211.76 1761.34 217.457 1756.04 222.295C1750.89 226.977 1744.72 230.567 1737.54 233.064C1730.37 235.561 1722.17 236.809 1712.96 236.809ZM1687.45 163.536L1684.64 160.727H1741.06L1738.48 163.536C1738.48 157.917 1737.15 153.469 1734.5 150.192C1731.85 146.759 1728.57 144.262 1724.67 142.701C1720.92 141.14 1717.26 140.36 1713.67 140.36C1710.08 140.36 1706.25 141.14 1702.2 142.701C1698.14 144.262 1694.63 146.759 1691.66 150.192C1688.85 153.469 1687.45 157.917 1687.45 163.536Z" fill="white" />
      <path d="M1520.6 234V70.1299H1551.5V212.229L1549.87 234H1520.6ZM1586.85 236.809C1577.02 236.809 1568.67 234.312 1561.8 229.318C1555.09 224.324 1549.94 217.223 1546.35 208.015C1542.76 198.807 1540.97 187.96 1540.97 175.475C1540.97 162.834 1542.76 151.987 1546.35 142.935C1549.94 133.727 1555.09 126.626 1561.8 121.632C1568.67 116.638 1577.02 114.141 1586.85 114.141C1597.62 114.141 1606.91 116.638 1614.71 121.632C1622.67 126.626 1628.84 133.727 1633.21 142.935C1637.58 151.987 1639.76 162.834 1639.76 175.475C1639.76 187.96 1637.58 198.807 1633.21 208.015C1628.84 217.223 1622.67 224.324 1614.71 229.318C1606.91 234.312 1597.62 236.809 1586.85 236.809ZM1578.89 208.483C1584.51 208.483 1589.43 207.079 1593.64 204.269C1598.01 201.46 1601.45 197.558 1603.94 192.564C1606.44 187.57 1607.69 181.874 1607.69 175.475C1607.69 169.076 1606.44 163.38 1603.94 158.386C1601.6 153.392 1598.25 149.568 1593.88 146.915C1589.66 144.106 1584.75 142.701 1579.13 142.701C1573.82 142.701 1569.06 144.106 1564.85 146.915C1560.63 149.724 1557.36 153.626 1555.02 158.62C1552.67 163.458 1551.5 169.076 1551.5 175.475C1551.5 181.874 1552.67 187.57 1555.02 192.564C1557.36 197.558 1560.56 201.46 1564.61 204.269C1568.83 207.079 1573.59 208.483 1578.89 208.483Z" fill="white" />
      <path d="M1316.45 234V116.95H1345.48L1346.88 132.166C1350.47 126.236 1355.08 121.788 1360.7 118.823C1366.31 115.701 1372.56 114.141 1379.42 114.141C1388.94 114.141 1396.9 116.169 1403.3 120.227C1409.86 124.285 1414.62 130.528 1417.58 138.955C1421.02 130.996 1425.85 124.909 1432.1 120.695C1438.34 116.326 1445.6 114.141 1453.87 114.141C1467.6 114.141 1478.06 118.511 1485.24 127.25C1492.42 135.834 1496.01 149.1 1496.01 167.047V234H1465.1V174.07C1465.1 166.111 1464.25 159.946 1462.53 155.576C1460.81 151.207 1458.47 148.085 1455.51 146.212C1452.54 144.183 1449.03 143.169 1444.97 143.169C1437.48 143.013 1431.71 145.432 1427.65 150.426C1423.59 155.42 1421.56 162.833 1421.56 172.666V234H1390.66V174.07C1390.66 166.111 1389.8 159.946 1388.09 155.576C1386.53 151.207 1384.18 148.085 1381.06 146.212C1378.1 144.183 1374.59 143.169 1370.53 143.169C1363.04 143.013 1357.26 145.432 1353.21 150.426C1349.3 155.42 1347.35 162.833 1347.35 172.666V234H1316.45Z" fill="white" />
      <path d="M1239.73 236.809C1228.19 236.809 1217.96 234.234 1209.07 229.084C1200.17 223.778 1193.15 216.52 1188 207.313C1183 198.105 1180.51 187.492 1180.51 175.475C1180.51 163.458 1183.08 152.845 1188.23 143.637C1193.54 134.429 1200.72 127.25 1209.77 122.1C1218.98 116.794 1229.51 114.141 1241.37 114.141C1251.67 114.141 1261.12 116.872 1269.7 122.334C1278.44 127.64 1285.38 135.522 1290.53 145.978C1295.84 156.279 1298.49 168.842 1298.49 183.668H1209.77L1212.58 180.859C1212.58 186.634 1213.98 191.706 1216.79 196.076C1219.76 200.29 1223.5 203.567 1228.03 205.908C1232.71 208.093 1237.71 209.185 1243.01 209.185C1249.41 209.185 1254.64 207.859 1258.7 205.206C1262.75 202.396 1265.88 198.807 1268.06 194.437L1295.68 205.206C1292.41 211.76 1288.11 217.457 1282.81 222.295C1277.66 226.977 1271.49 230.567 1264.31 233.064C1257.14 235.561 1248.94 236.809 1239.73 236.809ZM1214.22 163.536L1211.41 160.727H1267.83L1265.25 163.536C1265.25 157.917 1263.92 153.469 1261.27 150.192C1258.62 146.759 1255.34 144.262 1251.44 142.701C1247.69 141.14 1244.03 140.36 1240.44 140.36C1236.85 140.36 1233.02 141.14 1228.97 142.701C1224.91 144.262 1221.4 146.759 1218.43 150.192C1215.62 153.469 1214.22 157.917 1214.22 163.536Z" fill="white" />
      <path d="M990.553 234V70.1299H1025.43L1073.19 140.828L1120.95 70.1299H1155.59V234H1123.76V120.227L1073.19 194.671L1022.62 120.461V234H990.553Z" fill="white" />
      <path d="M928.494 234V70.1299H959.395V234H928.494Z" fill="white" />
      <path d="M851.777 236.809C840.228 236.809 830.006 234.234 821.11 229.084C812.214 223.778 805.191 216.52 800.041 207.313C795.047 198.105 792.55 187.492 792.55 175.475C792.55 163.458 795.125 152.845 800.275 143.637C805.581 134.429 812.76 127.25 821.812 122.1C831.02 116.794 841.555 114.141 853.416 114.141C863.716 114.141 873.158 116.872 881.742 122.334C890.482 127.64 897.426 135.522 902.577 145.978C907.883 156.279 910.536 168.842 910.536 183.668H821.812L824.621 180.859C824.621 186.634 826.026 191.706 828.835 196.076C831.8 200.29 835.546 203.567 840.072 205.908C844.754 208.093 849.748 209.185 855.054 209.185C861.453 209.185 866.681 207.859 870.739 205.206C874.797 202.396 877.918 198.807 880.103 194.437L907.727 205.206C904.45 211.76 900.158 217.457 894.851 222.295C889.701 226.977 883.537 230.567 876.357 233.064C869.178 235.561 860.985 236.809 851.777 236.809ZM826.26 163.536L823.451 160.727H879.869L877.294 163.536C877.294 157.917 875.967 153.469 873.314 150.192C870.661 146.759 867.384 144.262 863.482 142.701C859.736 141.14 856.069 140.36 852.479 140.36C848.89 140.36 845.066 141.14 841.008 142.701C836.951 144.262 833.439 146.759 830.474 150.192C827.665 153.469 826.26 157.917 826.26 163.536Z" fill="white" />
      <path d="M711.308 234L755.787 116.95H787.624L739.634 234H711.308ZM708.733 234L660.742 116.95H692.58L737.059 234H708.733Z" fill="white" />
      <path d="M600.988 236.809C589.439 236.809 579.217 234.234 570.321 229.084C561.425 223.778 554.402 216.52 549.252 207.313C544.258 198.105 541.76 187.492 541.76 175.475C541.76 163.458 544.336 152.845 549.486 143.637C554.792 134.429 561.971 127.25 571.023 122.1C580.231 116.794 590.765 114.141 602.627 114.141C612.927 114.141 622.369 116.872 630.953 122.334C639.692 127.64 646.637 135.522 651.788 145.978C657.094 156.279 659.747 168.842 659.747 183.668H571.023L573.832 180.859C573.832 186.634 575.237 191.706 578.046 196.076C581.011 200.29 584.757 203.567 589.283 205.908C593.965 208.093 598.959 209.185 604.265 209.185C610.664 209.185 615.892 207.859 619.95 205.206C624.008 202.396 627.129 198.807 629.314 194.437L656.938 205.206C653.66 211.76 649.369 217.457 644.062 222.295C638.912 226.977 632.747 230.567 625.568 233.064C618.389 235.561 610.196 236.809 600.988 236.809ZM575.471 163.536L572.662 160.727H629.08L626.505 163.536C626.505 157.917 625.178 153.469 622.525 150.192C619.872 146.759 616.595 144.262 612.693 142.701C608.947 141.14 605.28 140.36 601.69 140.36C598.101 140.36 594.277 141.14 590.219 142.701C586.162 144.262 582.65 146.759 579.685 150.192C576.876 153.469 575.471 157.917 575.471 163.536Z" fill="white" />
      <path d="M426.792 234V70.1299H458.863V204.738H530.966V234H426.792Z" fill="white" />
      <path d="M3.5047e-05 105C4.51862e-05 47.0101 47.0102 8.21951e-06 105 1.83588e-05L201.272 3.51915e-05C259.262 4.53307e-05 306.272 47.0102 306.272 105L306.272 201.272C306.272 259.262 259.262 306.272 201.272 306.272L105 306.272C47.0101 306.272 8.07501e-06 259.262 1.82143e-05 201.272L3.5047e-05 105Z" fill="url(#paint0_linear_85_289)" />
      <path d="M83 84C83 78.4771 87.4772 74 93 74H130C135.523 74 140 78.4772 140 84V221C140 226.523 135.523 231 130 231H93C87.4772 231 83 226.523 83 221V84Z" fill="white" />
      <path d="M213 174C218.523 174 223 178.477 223 184L223 221C223 226.523 218.523 231 213 231L93 231C87.4772 231 83 226.523 83 221L83 184C83 178.477 87.4772 174 93 174L213 174Z" fill="white" />
      <path d="M220.089 103.011C223.97 105.69 223.97 111.31 220.089 113.989L179.865 141.754C175.303 144.903 169 141.719 169 136.265L169 80.7345C169 75.2808 175.303 72.0967 179.865 75.2458L220.089 103.011Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_85_289" x1="298.19" y1="306.272" x2="3.47254e-05" y2="11.0598" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BD39FF" />
          <stop offset="0.197699" stop-color="#F84291" />
          <stop offset="0.397699" stop-color="#FF2121" />
          <stop offset="0.607699" stop-color="#FF631B" />
          <stop offset="0.792699" stop-color="#FFD000" />
          <stop offset="1" stop-color="#FF9720" />
        </linearGradient>
      </defs>
    </svg>
    <p>Essa área foi criada com LevelMember. No momento, está offline. Por favor, tente novamente mais tarde ou entre em contato com o suporte do proprietário da área.</p>
  </section>
</template>

<script setup>
import { onMounted } from 'vue'

function corrigirCLSPageSpeed() {
  const section = document.body
  section.style.visibility = 'visible'
}

onMounted(() => {
  corrigirCLSPageSpeed()
})
</script>

<style scoped>
section.erro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100dvh;
  width: 100%;
  background-image: url('https://arquivos.levelmember.com/imagens/web/background.png');
  background-size: 100% 100%;
  background-position: center;
  background-color: #000;
}
svg {
  width: 100%;
  max-width: 160px;
  margin: 0 0 20px 0;
}
p {
  font-family: 'Figtree-Light';
  font-size: 14px;
  color: #ffffff;
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
}
@media screen and (max-width: 1000px) {
  section.erro {
    background-image: url('https://arquivos.levelmember.com/imagens/web/background-mobile.png');
  }
  svg {
    max-width: 130px;
  }
  p {
    max-width: 280px;
  }
}
</style>
