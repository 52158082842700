import { api } from './axios'

export default {
  receberPorHotlink(hotlink) {
    const method = 'GET'
    const url = `areas/hotlink/${hotlink}`

    return api(method, url, null, null)
  },
  receberPorDominioPersonalizado(dominio) {
    const method = 'GET'
    const url = `areas/dominio/${dominio}`

    return api(method, url, null, null)
  }
}
